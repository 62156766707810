<template>
	<privacy-policy />
</template>

<script>
import PrivacyPolicy from '../components/legal/PrivacyPolicy.vue';

export default {
  components: {
    PrivacyPolicy,
  },
	data: () => ({

	}),
	methods: {

	},
	computed: {

	},
	watch: {

	},
	mounted () {

	}
}
</script>

<style lang="scss" scoped>

</style>

 <style>
  