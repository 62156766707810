<template>
  <div>
    <v-dialog v-model="shareWithFriends" width="500px">
      <v-card color="white" light v-if="userID">
        <v-card-title>Share With:</v-card-title>
        <friends-list></friends-list>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FriendsList from "../shares/FriendsList.vue";

export default {
  props: ["actionID", "movieID", "mediaType"],
  components: {
    FriendsList,
  },
  data: () => ({
    shareWithFriends: false,
  }),
  methods: {
    addToWatchList() {
      // console.log("adding");
      if (this.userID) {
        this.$store.dispatch("ADD_MOVIE_TO_WATCH_LIST", {
          id: this.movieID,
          media: this.mediaType,
        });
      } else {
        this.$store.commit("TOGGLE_ACCOUNT_MODAL", true);
      }
    },
    shareMovie() {
      // console.log('share');
      this.shareWithFriends = false;
      if (this.userID) {
        this.$store.commit("SET_CURRENT_SHARE_MOVIE", {
          id: this.movieID,
          media: this.mediaType,
        });
        this.shareWithFriends = true;
      } else {
        this.$store.commit("TOGGLE_ACCOUNT_MODAL", true);
      }
    },
    markAsWatched() {
      console.log('watched');
      if (this.userID) {
        this.$store.dispatch("MARK_MOVIE_AS_WATCHED", {
          id: this.movieID,
          media: this.mediaType,
        });
      } else {
        this.$store.commit("TOGGLE_ACCOUNT_MODAL", true);
      }
    },
    removeFromWatchList() {
      console.log('removed');
      if (this.userID) {
        this.$store.dispatch("REMOVE_MOVIE_FROM_WATCH_LIST", {
          id: this.movieID,
          media: this.mediaType,
        });
      } else {
        this.$store.commit("TOGGLE_ACCOUNT_MODAL", true);
      }
    },
  },
  computed: {
    userID() {
      return this.$store.state.user.id;
    },
    shareSent () {
      return this.$store.state.shareSent;
    }
  },
  watch: {
    actionID() {
      // console.log("action ", this.actionID);
      // console.log("movie ", this.movieID);
      // console.log("media ", this.mediaType);
      switch (this.actionID) {
        case 101:
          this.addToWatchList();
          break;
        case 102:
          this.shareMovie();
          break;
        case 103:
          this.shareMovie();
          break;
        case 104:
          this.markAsWatched();
          break;
        case 105:
          this.removeFromWatchList();
          break;
        default:
          throw new Error("Unsupported movie action");
      }
    },
    shareSent (newVal) {
      if (newVal) {
        this.shareWithFriends = false;
      }
    }
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
</style>