import { version } from '../../package.json';

const state = {
	navDrawer: null,
	user: {
		id: '',
		email: '',
		firstName: '',
		lastName: '',
		password: '',
		created_at: ''
	},
	appVersion: version,
	forgotPasswordEmailSuccess: false,
	forgotPasswordToken: '',
	invalidTokenError: false,
	validTokenSuccess: false,
	newUserPassword: '', // used in resetting user password
	newPasswordSetSuccess: false,
	newPasswordSetError: false,
	updatePassword: {
		currentPassword: '',
		newPassword: ''
	},
	showUserSearch: false, // only used for mobile views
	tmdbAPIKey: 'f1eb310dd7f9fd012c0dcf7e2f9c19cd', // TODO: Should hide this in the API
	trendingMovies: [], // from trending movies API
	trendingShows: [], // from trending shows API
	movieDetailView: {
		id: null,
		media: null
	},
	movieDetailData: [], // from TMDB, holds movie details from trending movies/shows
	movieCreditsData: [], // from TMDB, holds credits/cast data for movies/shows
	movieGenreList: [], // from TMDB, holds list of categories/genres
	searchResults: [],
	showAccountModal: false,
	userMovieFavorites: [], // user movie favorite data from My API
	populatedMovieFavorites: [], // movie details from OMDB for movies in userMovieFavorites
	friendsList: [],
	pendingFriendRequests: [],
	sentFriendRequests: [],
	notifications: [],
	showShareModal: false,
	showRespondShareModal: false,
	currentShare: {
		share_to_email: "",
		movie_id: "",
		media_type: "",
		share_from_comments: "",
		share_from_excite: "",
	},
	current_response_share_id: null, // Not sure why this was necessary??
	currentResponse: {
		share_id: '', // undefined for some reason
		respond_to_email: "",
		movie_id: "",
		media_type: "",
		respond_comments: "",
		respond_excite: "",
	},
	shareSent: false,
	// incomingShares: [],
	// outgoingShares: [],
	populatedIncomingShares: [],
	populatedOutgoingShares: [],
	incomingMatches: [],
	populatedMatches: [],
	entities: {
		shares: {},
		comments: {}
	},
	results: { // RESULTS ARE FOR TOP-LEVEL PARENT DATA ELEMENTS
		shares: [],
	}

};

export default state;