<template>
	<v-row class="mt-5">
    <search-field />
  </v-row>
</template>

<script>
import SearchField from './SearchField.vue';
export default {
  components: {
    SearchField,

  },
	data: () => ({

	}),
	methods: {

	},
	computed: {

	},
	watch: {

	},
	mounted () {

	}
}
</script>

<style lang="scss" scoped>

</style>