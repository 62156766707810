<template>
	<div>
		<v-row v-if="userID">
			<v-col cols="12" md="5">
				<friends-list></friends-list>
			</v-col>
		</v-row>
		<v-row v-else>
			<v-col cols="12" md="5">
				<new-user-friends />
			</v-col>
		</v-row>
		<v-row v-if="userID">
			<v-col cols="12" md="5">
				<v-tabs fixed-tabs v-model="tab">
					<v-tab>Pending</v-tab>
					<v-tab>Sent</v-tab>
				</v-tabs>
				<v-tabs-items v-model="tab">
					<v-tab-item>
						<pending-friend-requests></pending-friend-requests>
					</v-tab-item>
					<v-tab-item>
						<sent-friend-requests></sent-friend-requests>
					</v-tab-item>
				</v-tabs-items>
			</v-col>
		</v-row>
	</div>
	
</template>

<script>

import FriendsList from '../components/friends/FriendsList.vue';
import PendingFriendRequests from '../components/friends/PendingFriendRequests.vue';
import SentFriendRequests from '../components/friends/SentFriendRequests.vue';
import NewUserFriends from '../components/friends/NewUserFriends.vue';

export default {
	components: {
		FriendsList,
		PendingFriendRequests,
		SentFriendRequests,
		NewUserFriends,

	},
	data: () => ({
		tab: ''
	}),
	computed: {
		userID () {
			return this.$store.state.user.id;
		}
	},
	mounted () {
		this.$store.dispatch('GET_MY_FRIENDS_LIST');
	}
}
</script>FriendsList