import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import OneSignal from "onesignal-vue";

Vue.config.productionTip = false;
Vue.use(OneSignal);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  beforeMount() {
    this.$OneSignal.init({
      appId: process.env.VUE_APP_ONE_SIGNAL_ID,
      safari_web_id: process.env.VUE_APP_ONE_SIGNAL_SAFARI,
      notifyButton: {
        enable: true,
      },
      allowLocalhostAsSecureOrigin: true,
    });
  },
}).$mount('#app');
