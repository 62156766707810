<template>
	<v-card>
		<v-card-title>Friends</v-card-title>
		<v-card-text>
			<v-list v-if="friendsList.length">
				<v-list-item v-for="friend in friendsList" :key="friend.email">
					<v-list-item-avatar color="primary">
						{{friend.initials}}
					</v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title>
							{{friend.first_name}} : {{friend.email}}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			

			<div v-if="!friendsList.length">Streamzy is better with friends. Send an invitation!</div>
		</v-card-text>
		<v-card-title>
			Invite a New Friend
		</v-card-title>
		<v-card-text>
			<v-form v-model="isValidEmail" @submit.prevent="">
				<v-text-field label="Friend's Email" v-model="friendsEmail" :rules="[rules.required, rules.email]"
					v-on:keyup.enter="sendFriendRequest" outlined :disabled="disableEmail">
					<template v-slot:append>
						<v-icon v-show="requestSent" color="green">mdi-check-circle-outline</v-icon>
					</template>
				</v-text-field>
				<input type="hidden" />
			</v-form>
			<p class="card-text">If the friend you invite isn't a Streamzy member yet, they'll get an email inviting them to join.</p>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="primary" @click="clearForm" :disabled="sendAnotherDisabled" outlined>Send Another</v-btn>
			<v-btn color="primary" @click="sendFriendRequest" :disabled="!isValidEmail">Send Invite!</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
	data: () => ({
		friendsEmail: '',
		requestSent: false,
		isValidEmail: false,
		sendAnotherDisabled: true,
		disableEmail: false,
		rules: {
			required: value => !!value || 'Required.',
			email: value => {
				const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				return pattern.test(value) || 'Invalid e-mail.'
			},
			
		},
	}),
	methods: {
		sendFriendRequest () {
			this.$store.dispatch('SEND_NEW_FRIEND_REQUEST', this.friendsEmail);
			this.requestSent = true;
			this.isValidEmail = false;
			this.disableEmail = true;
			this.sendAnotherDisabled = false;
		},
		clearForm () {
			this.friendsEmail = '';
			this.requestSent = false;
			this.disableEmail = false;
			this.sendAnotherDisabled = true;
		}
	},
	computed: {
		friendsList () {
			return this.$store.state.friendsList;
		}
	},
	mounted () {

	}
}
</script>

