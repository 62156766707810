<template>
<v-row>
	<v-col cols="12" md="5">
		<user-profile v-if="userID"></user-profile>
		<div v-else>
			<v-card>
				<v-card-title>Nothing To See Here Yet</v-card-title>
				<v-card-text></v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<create-account-button />
				</v-card-actions>
			</v-card>
		</div>
	</v-col>
</v-row>
</template>

<script>
import UserProfile from '../components/user-profile/UserProfile.vue';
import CreateAccountButton from '../components/CreateAccountButton.vue';

export default {
	components: {
		UserProfile,
		CreateAccountButton,
	},
	data: () => ({

	}),
	methods: {

	},
	computed: {
		userID () {
			return this.$store.state.user.id;
		}
	},
	watch: {

	},
	mounted () {

	}
}
</script>

<style lang="scss" scoped>

</style>