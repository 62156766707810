<template>
	<v-dialog v-model="showShareModal" width="500" light>
		<v-card light>
			<v-app-bar flat color="white">
				<v-spacer />
				<v-btn icon @click="closeShareModal">
					<v-icon x-large color="primary">mdi-close-circle-outline</v-icon>
				</v-btn>
			</v-app-bar>
			<v-card-title class="justify-center">Share With: {{currentShare.share_to_email}}
				
			</v-card-title>
			<v-card-text>
				<v-row>
					<!-- <v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon :class="{ 'show-btns': hover }" :color="transparent" 
							@click="addToWatchList($event)" v-bind="attrs" v-on="on">
								<v-icon :class="{ 'show-btns': hover }" :color="transparent">mdi-eye-plus</v-icon>
							</v-btn>
						</template>
						<span>Add to your Watch List</span>
					</v-tooltip> -->
					
					<v-col>
						<p>Rate Your Excitement: (Optional)</p>
					</v-col>
					<v-col>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn fab depressed v-bind="attrs" v-on="on" 
									id="meh" @click="setExciteLevel($event)" :color="isActive('meh') ? 'primary' : null">
									<v-icon x-large>mdi-emoticon-neutral-outline</v-icon>
								</v-btn>
							</template>
							<span>Meh??</span>
						</v-tooltip>
					</v-col>
					<v-col>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn fab depressed v-bind="attrs" v-on="on" 
									id="like" @click="setExciteLevel($event)" :color="isActive('like') ? 'primary' : null">
									<v-icon x-large>mdi-emoticon-happy-outline</v-icon>
								</v-btn>
							</template>
							<span>Looks Good!</span>
						</v-tooltip>
					</v-col>
					<v-col>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn fab depressed v-bind="attrs" v-on="on" 
									id="love" @click="setExciteLevel($event)" :color="isActive('love') ? 'primary' : null">
									<v-icon x-large>mdi-emoticon-excited-outline</v-icon>
								</v-btn>
							</template>
							<span>Can't Wait!!</span>
						</v-tooltip>
					</v-col>

				</v-row>
				<v-icon></v-icon>
				<v-text-field outlined v-model="shareComments" placeholder="Comments? (Optional)"></v-text-field>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn depressed color="primary" @click="sendShare" v-if="!this.shareSent">Send It</v-btn>
				<v-btn v-if="this.shareSent" color="green" depressed>
					<v-icon color="white">mdi-check-circle-outline</v-icon>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>

export default {
	data: () => ({
		shareExciteLevel: 1, // 1 is default level
		shareComments: '',
		shareSent: false,
		exciteLevelMap: {
			meh: 0,
			like: 1,
			love: 2
		},
	}),
	methods: {
		closeShareModal () {
			this.$store.commit('CLOSE_SHARE_MODAL');
		},
		setExciteLevel (event) {
			let id = null;
			let x = 0;
			while (!id) {
				id = event.path[x].id;
				x++;
			}
			// console.log(id)
			this.shareExciteLevel = this.findExciteLevel(id);
		},
		findExciteLevel(level) {
			return this.exciteLevelMap[level];
		},
		sendShare () {
			this.$store.commit('SET_CURRENT_SHARE_DETAILS', { 
				comments: this.shareComments, exciteLevel: this.shareExciteLevel 
			});
			this.$store.dispatch('SEND_NEW_MOVIE_SHARE');
			this.shareSent = true;
			setTimeout(() => {
				this.shareSent = false;
				this.$store.commit('CLOSE_SHARE_MODAL');
				this.shareExciteLevel = 1;
				this.shareComments = '';
			}, 1500);
		},
		isActive(id) {
			return this.exciteLevelMap[id] === this.shareExciteLevel;
		}

	},
	computed: {
		showShareModal () {
			return this.$store.state.showShareModal;
		},
		currentShare () {
			return this.$store.state.currentShare;
		}

	},
	watch: {
		// shareExciteLevel () {
		// 	console.log(this.shareExciteLevel)
		// }
	}
}
</script>

<style lang="scss">
	.active {
		color: blue !important;
		background-color: blue !important;
	}
</style>