import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import MovieDetailView from '../views/MovieDetailView.vue';
import WatchList  from '../views/WatchList.vue';
import FriendsListPage from '../views/FriendsListPage.vue';
import SharesPage from '../views/SharesPage.vue';
import MatchesPage from '../views/MatchesPage.vue';
import UserProfilePage from '../views/UserProfilePage.vue';
import SearchResultsPage from '../views/SearchResultsPage.vue';
import ResetPasswordPage from '../views/ResetPasswordPage';
import PrivacyPage from '../views/PrivacyPage.vue';
import About from '../views/About.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Streamzy',
		component: Home
	},
	{
		path: '/movie-detail',
		name: 'Movie Details',
		component: MovieDetailView
	},
	{
		path: '/watch-list',
		name: 'Watch List',
		component: WatchList
	},
	{
		path: '/friends',
		name: 'Friends List',
		component: FriendsListPage
	},
	{
		path: '/shares',
		name: 'Movie Shares',
		component: SharesPage
	},
	{
		path: '/matches',
		name: 'Movie Matches',
		component: MatchesPage
	},
	{
		path: '/profile',
		name: 'User Profile',
		component: UserProfilePage
	},
	{
		path: '/search-results',
		name: 'Search Results',
		component: SearchResultsPage
	},
	{
		path: '/reset-password',
		name: 'Reset Password',
		component: ResetPasswordPage
	},
	{
		path: '/privacy-policy',
		name: 'Privacy Policy',
		component: PrivacyPage
	},
	{
		path: '/about',
		name: 'About',
		component: About
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

export default router;
