<template>
	<v-text-field
		outlined
		clearable
		@click:clear="inputCleared"
		:append-icon="this.userSearch ? null : 'mdi-magnify'"
		placeholder="Search movies and tv shows..."
		v-model="userSearch"
		dense
		@keyup.enter="goForthAndSearch"
	>
	</v-text-field>
      
      <!-- <v-menu bottom offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          outlined
          clearable
          append-icon="mdi-search"
          placeholder="Search movies and tv shows..."
          v-model="userSearch"
          dense
          @keyup.enter="goForthAndSearch"
          v-bind="attrs"
          v-on="on"
        >
        </v-text-field>
      </template>

      <v-list dense>
        <v-subheader>Search by Genre</v-subheader>
        <v-list-item-group v-model="searchMenuSelection">
          <v-list-item
            v-for="genre in movieGenres"
            :key="genre.id"
            :value="genre"
          >
            <v-list-item-title>{{ genre.name }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu> -->


</template>

<script>
export default {
  data: () => ({
    userSearch: null,
    searchMenuSelection: null,
  }),
  methods: {
    goForthAndSearch() {
      console.log("search me " + this.userSearch);
      this.$router.push({
        path: "/search-results",
        query: {
          search: this.userSearch,
        },
      });
    },
    inputCleared() {
      console.log("cleared");
      this.$router.push("/");
    },
  },
  computed: {
    movieGenres() {
      return this.$store.state.movieGenreList;
    },
  },
  watch: {
    searchMenuSelection() {
      // console.log(this.searchMenuSelection)
      const genre = this.searchMenuSelection.name;
      const id = this.searchMenuSelection.id;
      this.userSearch = genre;
      this.$router.push({
        path: "/search-results",
        query: {
          id,
          genre,
          media: "tv,movie",
          services: "8,119,9,337,390,384,280,2,350",
          sort: "popularity.desc",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// .v-input__control {
// 	transform: scale(.65);
// }
</style>