<template>
	<v-app>
		<nav-drawer></nav-drawer>
		<nav-bar></nav-bar>
		<v-main>
		<search-field v-if="isOnMobileDevice && showSearchField" class="mt-2"></search-field>
			<v-container fluid>
				<router-view />
			</v-container>
			<cookie-bar />
		</v-main>
		
		<v-footer>
			<v-col class="text-center" cols="12">
				{{ new Date().getFullYear() }} - <strong>Streamzy</strong> - v. {{ appVersion }} | 
				<v-btn text to="/privacy-policy">Privacy Policy</v-btn> |
				<v-btn text to="/about">About</v-btn>
			</v-col>
		</v-footer>
		<account-modal />
		<share-modal />
		<respond-share-modal />
	</v-app>
</template>

<script>
import AccountModal from "./components/AccountModal.vue"
import ShareModal from './components/shares/ShareModal.vue';
import RespondShareModal from './components/shares/RespondShareModal.vue';
import NavDrawer from './components/app/NavDrawer.vue';
import NavBar from './components/app/NavBar.vue';
import SearchField from './components/search/SearchField.vue';
import CookieBar from './components/legal/CookieBar.vue';
// import library from './library/library';

export default {
	components: { 
		AccountModal,
		ShareModal,
		RespondShareModal,
		NavDrawer,
		NavBar,
		SearchField,
		CookieBar,
		
	},
	data: () => ({
		
	}),
	methods: {
		loginFromLocalStorage () {
			console.log('logging in from local');
			const email = localStorage.getItem('email');
			const password = localStorage.getItem('password');

			if (email && password) {
				this.$store.commit('SET_USER_EMAIL', email);
				this.$store.commit('SET_USER_PASSWORD', password);
				this.$store.dispatch('LOGIN_USER');
			}
		},
	},
	
	computed: {
		userID () {
			return this.$store.state.user.id;
		},
		currentShareTo () {
			return this.$store.state.currentShare.share_to_email;
		},
		appVersion () {
			return this.$store.state.appVersion;
		},
		isOnMobileDevice() {
      return this.$vuetify.breakpoint.mobile;
    },
		showSearchField () {
			return this.$store.state.showUserSearch;
		},
		
	},
	watch: {
		async userID () {
			if (this.userID) {
				// console.log('user logged in');
				await this.$store.dispatch('LOAD_INITIAL_USER_DATA');
				this.$OneSignal.showSlidedownPrompt(); // show opt-in for notis
				/* Not sure if we need to verify user opted in before setting ext user id? */
				// library.setOneSignalExternalUserID(this.userID);
				this.$OneSignal.setExternalUserId(this.userID);
			}
		},
		currentShareTo (newVal) {
			if (newVal) {
				this.$store.commit('SHOW_SHARE_MODAL');
			}
		},
		/* NAVIGATION GUARDS */
		// eslint-disable-next-line
		$route (to, from) {
			if (this.userID) {
				this.$store.dispatch('GET_MY_NOTIFICATIONS'); // update Notifications on route change
			}
		}
	},
	async mounted () {
		this.loginFromLocalStorage();
		this.$store.dispatch('LOAD_INITIAL_MOVIE_DATA');
	}
};
</script>

<style lang="scss">
	.app-title {
		font-size: 18px;
		color: #B078FF;
	}

	.card-text {
		font-size: 16px;
	}
</style>