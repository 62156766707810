<template>
	<v-dialog v-model="showModal" width="500" light>
		<v-card dark>
			<v-tabs v-model="tab" grow>
				<v-tab>Create Account</v-tab>
				<v-tab>Login</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tab" dark>
				<account-modal-register></account-modal-register>
				<account-modal-login></account-modal-login>
			</v-tabs-items>
		</v-card>
	</v-dialog>
</template>

<script>
import AccountModalLogin from './account-modal/AccountModalLogin.vue';
import AccountModalRegister from './account-modal/AccountModalRegister.vue';

export default {
	components: {
		AccountModalRegister,
		AccountModalLogin
	},
	data: () => ({
		tab: 'register',
		tabItems: ['register', 'login']
	}),
	methods: {
		closeModal () {
			this.$store.commit('TOGGLE_ACCOUNT_MODAL', false);
		}

	},
	computed: {
		showModal: {
			get: function () {
				return this.$store.state.showAccountModal;
			},
			set: function (val) {
				this.$store.commit('TOGGLE_ACCOUNT_MODAL', val);
			}
		},

	}
}
</script>