<template>
  <v-dialog v-model="showPasswordModal" width="500" light>
		<v-card dark height="100%">
			<v-card-title>Forgot Password?
        <v-spacer></v-spacer>
        <v-btn icon @click="closePasswordModal">
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <p>If you've forgotten your password, enter the email you used to register here, and we'll
          send you a password reset link:
        </p>
        <v-form v-model="emailValid">
          <v-text-field label="email" v-model="email" :disabled="emailInputDisabled"
            :rules="[rules.required, rules.email]"></v-text-field>
        </v-form>
        <p v-if="emailSubmitted">
          <v-icon color="success">mdi-check-circle-outline</v-icon>
          Check your email for instructions to reset your password.</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" depressed :disabled="sendItDisabled"
          @click="sendPasswordReset">Send It</v-btn>
      </v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
  props: ['showPasswordModal'],
  data: () => ({
    emailValid: false,
    email: '',
    rules: {
			required: value => !!value || 'Required.',
			email: value => {
				const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				return pattern.test(value) || 'Invalid e-mail.'
			},
		},
  }),
  methods: {
    sendPasswordReset () {
      let email = this.email.trim().toLowerCase();
      this.$store.dispatch('RESET_USER_PASSWORD', email);
    },
    closePasswordModal () {
      this.$emit('closePassword');
    }
  },
  computed: {
    emailSubmitted () {
      return this.$store.state.forgotPasswordEmailSuccess;
    },
    sendItDisabled () {
      return !this.emailValid || this.emailSubmitted;
    },
    emailInputDisabled () {
      return this.emailSubmitted;
    }
  }
}
</script>