import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
// import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify, {
	options: {
		customProperties: true
	}
});

export default new Vuetify({
	theme: {
		dark: true,
		themes: {
			dark: {
				primary: "#B078FF"
			}
		}
	}
});
