<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" icon v-bind="attrs" v-on="on">
          <v-icon>{{
            movieIsOnWatchList(movie.id) ? "mdi-star" : "mdi-dots-vertical"
          }}</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="item in currentMovieActions(movie.id)"
          :key="item.id"
          @click="movieActionChoice(item.id, movie.id, movie.media_type)"
        >
          <v-list-item-action>
            <v-icon :color="item.iconColor">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <movie-action
      :actionID="actionID"
      :movieID="movieID"
      :mediaType="mediaType"
    />
  </div>
</template>

<script>
import MovieAction from './MovieAction.vue';

export default {
  components: {
    MovieAction,
  },
  props: ['movie'],
  data: () => ({
    actionID: null,
    movieID: null,
    mediaType: null,
    movieActions: [
      { id: 101, title: "Add to Watch List", icon: "mdi-eye-plus", iconColor: 'primary', showIfOnWatchList: false },
      { id: 102, title: "Share with Friend", icon: "mdi-share", iconColor: 'primary', showIfOnWatchList: false },
      // duplicating this isn't ideal but it works
      { id: 103, title: "Share with Friend", icon: "mdi-share", iconColor: 'primary', showIfOnWatchList: true },
      { id: 104, title: "Mark as Watched", icon: "mdi-star-outline", iconColor: 'primary', showIfOnWatchList: true },
      { id: 105, title: "Remove from Watch List", icon: "mdi-close-circle-outline", iconColor: 'red', showIfOnWatchList: true },
    ]
  }),
  methods: {
    movieIsOnWatchList(id) {
      return !!this.$store.state.userMovieFavorites.find(
        (movie) => movie.movie_id == id
      );
    },
    currentMovieActions (id) {
      return this.movieActions.filter(action => action.showIfOnWatchList == this.movieIsOnWatchList(id));
    },
    movieActionChoice (actionID, movieID, mediaType) {
      if (!mediaType) {
        mediaType = this.routeMediaType;
      }
      this.actionID = actionID;
      this.movieID = movieID;
      this.mediaType = mediaType;
    }
  },
  computed: {
    userID() {
      return this.$store.state.user.id;
    },
    routeMediaType () {
      return this.$route.query.media;
    }
  },
  watch: {

  },
  mounted() {

  },
};
</script>

<style lang="scss" scoped>
</style>

