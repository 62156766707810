<template>
	<v-snackbar v-model="snack" left :timeout="timeout" width="800">{{text}}
    <template v-slot:action="{ attrs }">
      <v-btn color="primary"  
        v-bind="attrs" @click="acceptCookies">Accept</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
	data: () => ({
    snack: false,
    text: "Streamzy uses cookies to improve your user experience. By using this website, you agree with our cookie and privacy policies.",
		timeout: -1,
	}),
	methods: {
    acceptCookies () {
      this.snack = false;
      const now = new Date().toISOString();
      localStorage.setItem('cookieAccept', now);
    },
    checkForAcceptedCookies () {
      const accepted = localStorage.getItem('cookieAccept');
      if (accepted) {
        this.snack = false;
      } else {
        this.snack = true;
      }
    }
	},
	computed: {

	},
	watch: {

	},
	mounted () {
    this.checkForAcceptedCookies();
	}
}
</script>

<style lang="scss" scoped>

</style>