<template>
	<v-card-text>
		<v-list>
			<v-list-item v-for="actor in cast" :key="actor.id">
				<v-list-item-content>
					<v-list-item-title>
						{{actor.name}} as {{actor.character}}
					</v-list-item-title>
				</v-list-item-content>
				<v-list-item-avatar>
					<v-img :src=setImageURL(actor.profile_path)></v-img>
				</v-list-item-avatar>
				
			</v-list-item>
		</v-list>
	</v-card-text>
</template>

<script>
export default {
	props: ['cast'],
	methods: {
		setImageURL (path) {
			return 'https://image.tmdb.org/t/p/w500/' + path;
		},
	}
}
</script>