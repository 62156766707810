<template>
	<v-tab-item>
		<v-card-title>
			Welcome back, friend!
		</v-card-title>
		<v-card-text>
			<div>How in the world did you get logged out?</div>
			<div>&nbsp;</div>
			<div>It was probably Dave's fault. I told him not to mess with that.</div>
			<div>&nbsp;</div>
			<div>** glares at Dave **</div>
			<v-form v-model="isLoginValid">
				<v-row class="mb-1">
					<v-col>
						<v-text-field label="Email" v-model="email" :rules="[rules.required, rules.email]"
							@input=setUserEmail></v-text-field>
					</v-col>
					<v-col>
						<v-text-field label="Password" v-model="password" :rules="[rules.required]"
							@input="setUserPassword"
							:append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
							:type="show ? 'text' : 'password'" @click:append="show = !show"></v-text-field>
					</v-col>
				</v-row>
			</v-form>
			<v-divider></v-divider>
			
		</v-card-text>
		<v-card-actions>
			<v-btn outlined @click="forgotPassword">Forgot password?</v-btn>
			<v-spacer></v-spacer>
			<!-- <v-btn depressed color="error" @click="closeModal">Close</v-btn> -->
			<v-btn depressed color="primary" :disabled="!isLoginValid"
				@click="loginUser">Login</v-btn>
		</v-card-actions>
		<!-- Handling an event emit from child -->
		<forgot-password :showPasswordModal="showPasswordModal" 
			v-on:closePassword="closePasswordModal" />
	</v-tab-item>
</template>

<script>
import ForgotPassword from './ForgotPassword.vue';
export default {
  components: { 
		ForgotPassword 
	},
	data: () => ({
		rules: {
			required: value => !!value || 'Required.',
			counter: value => value.length <= 20 || 'Max 20 characters',
			email: value => {
				const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				return pattern.test(value) || 'Invalid e-mail.'
			},
		},
		email: null,
		password: null,
		isLoginValid: false,
		show: false,
		showPasswordModal: false
	}),
	methods: {
		setUserEmail () {
			let email = this.email.trim().toLowerCase();
			this.$store.commit('SET_USER_EMAIL', email);
		},
		setUserPassword () {
			this.$store.commit('SET_USER_PASSWORD', this.password);
		},
		closeModal () {
			this.$store.commit('TOGGLE_ACCOUNT_MODAL', false);
		},
		async loginUser () {
			localStorage.setItem('email', this.email.trim().toLowerCase());
			localStorage.setItem('password', this.password);
			await this.$store.dispatch('LOGIN_USER');
			setTimeout(() => {
				this.email = '';
				this.password = '';
			}, 500);
		},
		forgotPassword () {
			// console.log('forgot password');
			this.showPasswordModal = true;
		},
		closePasswordModal () {
			this.showPasswordModal = false;
		}
	}

}
</script>