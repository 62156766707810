<template>
	<v-card>
		<v-card-title>Pending Friend Requests</v-card-title>
		<v-card-text>
			<v-list v-if="friendRequests.length" two-line>
				<v-list-item v-for="request in friendRequests" :key="request.id" :id="request.id">
					<v-list-item-content>
						<v-list-item-title>
							Invite From: {{request.requester_email}}
						</v-list-item-title>
						<v-list-item-subtitle>
							Invite Sent: {{new Date(request.created_at).toDateString()}}
						</v-list-item-subtitle>
					</v-list-item-content>
					<v-list-item-icon>
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn depressed @click="acceptFriendRequest($event)" v-bind="attrs" v-on="on">
									<v-icon color="green">mdi-thumb-up-outline</v-icon>
								</v-btn>
							</template>
							<span>Accept This Invite</span>
						</v-tooltip>
					</v-list-item-icon>
					<v-list-item-icon>
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn depressed @click="dismissFriendRequest($event)" v-bind="attrs" v-on="on">
									<v-icon color="error">mdi-thumb-down-outline</v-icon>
								</v-btn>
							</template>
							<span>Dismiss</span>
						</v-tooltip>
					</v-list-item-icon>
				</v-list-item>
			</v-list>
			<div v-else>Nothing here at the moment</div>
			<!-- TODO: I'd like these icons to be closer together
				Also, this is sort of a fun idea but a bit wonky in practicw -->
			<!-- <div v-else>
				<v-row class="justify-center"> 
					<v-col align="center">
						<v-icon color="primary">fa-frown</v-icon>
					</v-col>
					<v-col align="center">
						<v-icon>fa-arrow-alt-circle-right</v-icon>
					</v-col>
					<v-col align="center">
						<v-icon color="orange">fa-meh</v-icon>
					</v-col>
					<v-col align="center">
						<v-icon>fa-arrow-alt-circle-right</v-icon>
					</v-col>
					<v-col align="center">
						<v-icon color="purple">fa-smile</v-icon>
					</v-col>
				</v-row>
				<v-row>
					<v-col align="center">
						<span>Streamy is better with friends. Why not invite some?</span>
					</v-col>
				</v-row>
			</div> -->
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	data: () => ({

	}),
	methods: {
		acceptFriendRequest (event) {
			let x = 0;
			let id = null;
			while (!id) {
				id = event.path[x].id;
				x++;
			}
			const update = {
				requestID: id,
				status: 'accepted'
			}
			this.$store.dispatch('UPDATE_FRIEND_REQUEST', update);
		},
		dismissFriendRequest (event) {
			let x = 0;
			let id = null;
			while (!id) {
				id = event.path[x].id;
				x++;
			}
			const update = {
				requestID: id,
				status: 'dismissed'
			}
			this.$store.dispatch('UPDATE_FRIEND_REQUEST', update);
		},
	},
	computed: {
		friendRequests () {
			return this.$store.state.pendingFriendRequests;
		},
		userID () {
			return this.$store.state.user.id;
		}
	},
	async mounted () {
		if (this.userID) {
			await this.$store.dispatch('GET_MY_PENDING_FRIEND_REQUESTS');
		}
	}
}
</script>