<template>
  <v-hover v-slot="{ hover }">
    <v-card
      class="ma-2"
      :width="cardSize"
      tile
      :elevation="hover ? 10 : 2"
      :class="{ 'on-hover': hover }"
      :id="movie.id"
    >
      <v-img
        :src="setImageURL(movie.backdrop_path)"
        @click="viewDetails($event)"
      >
      </v-img>
      <v-card-title @click="viewDetails($event)"
        >{{
          movieTitle.length > 20
            ? movieTitle.slice(0, 16) + "..."
            : movieTitle.slice(0, 20)
        }}
      <v-spacer></v-spacer>
      <!-- MOVIE ACTION MENU -->
      <movie-action-menu :movie="movie"/>
      <!-- MOVIE TITLE -->
      </v-card-title>
      <v-row align="center" justify="start" class="mx-2">
        <v-rating
          :value="setMovieRating(movie.vote_average)"
          color="amber"
          dense
          half-increments
          readonly
          size="14"
        ></v-rating>

        <div class="grey--text ms-4">
          {{ movie.vote_average }} ({{ movie.vote_count }})
        </div>
        <v-spacer></v-spacer>
        <div v-if="movie.provider">
          <v-img
            :src="setImageURL(movie.provider.logo_path)"
            contain
            max-width="20"
          ></v-img>
        </div>
      </v-row>
      <v-card-text @click="viewDetails($event)">
        <div>{{ movie.overview.slice(0, 100) }}...</div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-show="routePath === '/'"
          text
          color="primary"
          @click="viewDetails($event)"
          >Details</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import MovieActionMenu from './MovieActionMenu.vue';

export default {
  components: {
    MovieActionMenu,
  },
  props: ["movie", "cardSize"],
  data: () => ({
    transparent: "rgba(0, 0, 0, 0)",
    purpleColor: "#B078FF",
    
  }),
  methods: {
    setImageURL(path) {
      return "https://image.tmdb.org/t/p/w500/" + path;
    },
    setMovieRating(rating) {
      return Math.ceil(rating) / 2;
    },
    
    viewDetails() {
      this.$router.push({
        path: "movie-detail",
        query: { id: this.movie.id, media: this.movie.media_type },
      });
    },
    
  },
  computed: {
    movieTitle() {
      return this.movie.title || this.movie.name;
    },
    isOnMobileDevice() {
      return this.$vuetify.breakpoint.mobile;
    },
    routePath() {
      return this.$route.path;
    },
    
    
  },
  watch: {

  },
  mounted() {

  },
};
</script>

<style lang="scss" scoped>
.show-btns {
  color: #b078ff !important; // TODO: Figure out how to use vuetify theme colors
}
// https://stackoverflow.com/questions/48280990/using-custom-theming-in-vuetify-and-pass-color-variables-to-components
</style>