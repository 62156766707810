<template>
  <div>
    <v-item-group v-if="searchResults.length">
      <v-item v-for="movie in searchResults" :key="movie.id">
        <movie-card :movie="movie" cardSize="500"></movie-card>
      </v-item>
    </v-item-group>
    <v-row v-else>
      <v-col cols="12" md="5">
        <v-card>
          <v-card-title>No Results Found</v-card-title>
          <v-card-text>Maybe try again?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <create-account-button />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MovieCard from '../components/movies/MovieCard.vue';

export default {
  components: {
    MovieCard,
  },
	data: () => ({

	}),
	methods: {
    searchByUserQuery () {
      // console.log(this.userSearch);
      this.$store.dispatch('SEARCH_MULTI_BY_USER_SEARCH', this.userSearch);
    },
    
	},
	computed: {
    userSearch () {
			return this.$route.query.search;
		},
    searchResults () {
			return this.$store.state.searchResults.filter(movie => movie.backdrop_path);
		},
    
	},
	watch: {

	},
	mounted () {
    this.searchByUserQuery();
	}
}
</script>

<style lang="scss" scoped>

</style>