<template>
	<v-btn v-if="!userID" @click="createAccount" color="primary" depressed>Create Account</v-btn>
</template>

<script>
export default {
	data: () => ({

	}),
	methods: {
    createAccount () {
      this.$store.commit('TOGGLE_ACCOUNT_MODAL', true);
    }
	},
	computed: {
		userID () {
			return this.$store.state.user.id;
		}
	},
	watch: {

	},
	mounted () {

	}
}
</script>

<style lang="scss" scoped>

</style>