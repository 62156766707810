import axios from 'axios';
import state from './state';
import { normalize } from 'normalizr';
import { shareSchema } from './entities';

const actions = {
	/* USER ACCOUNT INFO */
	async CREATE_NEW_ACCOUNT ({commit}) {
		const res = await axios.post(process.env.VUE_APP_API_URL + '/new-user', state.user);
		// console.log(res.data);
		commit('SET_USER_ID', res.data.id);
		commit('TOGGLE_ACCOUNT_MODAL', false);
	},

	async LOGIN_USER ({commit}) {
		const res = await axios.post(process.env.VUE_APP_API_URL + '/user-login', state.user);
		// console.log('login', res.data);
		commit('SET_LOGGED_IN_USER_DATA', res.data);
		commit('TOGGLE_ACCOUNT_MODAL', false);
	},

	/**
	 * Starts the initial User Password Reset process with an email sent
	 * @param commit 
	 * @param email 
	 */
	async RESET_USER_PASSWORD ({commit}, email) {
		await axios.post(process.env.VUE_APP_API_URL + '/forgot-password', { email });
		commit('RESET_PASSWORD_EMAIL_SUCCESS', true);
	},

	async VERIFY_PASSWORD_TOKEN ({commit}) {
		try {
			const res = await axios.post(process.env.VUE_APP_API_URL + '/verify-token', { token: state.forgotPasswordToken });
			console.log(res.data);
			commit('SET_VALID_TOKEN_SUCCESS', true);
		} catch (err) {
			console.log(err);
			commit('SET_INVALID_TOKEN_ERROR', true);
		}
		
	},

	async SAVE_NEW_USER_PASSWORD ({commit}) {
		try {
			const res = await axios.post(process.env.VUE_APP_API_URL + '/save-new-password', 
				{ 
					password: state.newUserPassword,
					token: state.forgotPasswordToken
				});
			console.log(res.data);
			commit('SET_NEW_PASSWORD_SUCCESS', true);
		} catch (err) {
			console.log(err);
			commit('SET_NEW_PASSWORD_ERROR', true);
		}
	},

	/**
	 * Updates a logged-in user password
	 * @param payload 
	 */
	// eslint-disable-next-line
	async UPDATE_USER_PASSWORD ({commit}, payload) {
		try {
			const res = await axios.post(process.env.VUE_APP_API_URL + '/update-password', {
				email: state.user.email,
				userID: state.user.id,
				currentPassword: state.updatePassword.currentPassword,
				newPassword: state.updatePassword.newPassword
			});
			console.log(res.data);
			// commit('SET_LOGGED_IN_USER_DATA', res.data);
			commit('SET_NEW_PASSWORD_SUCCESS', true);
		} catch (err) {
			console.log(err);
			commit('SET_NEW_PASSWORD_ERROR', true);
		}
	},

	/**
	 * Used on initial app render to load trending movie and show data from TMDB
	 */
	async LOAD_INITIAL_MOVIE_DATA ({dispatch}) {
		await dispatch('GET_TRENDING_MOVIES'); // initial trending movie API call
		await dispatch('GET_TRENDING_SHOWS'); // initial trending show API call
		await dispatch('GET_TRENDING_MOVIE_DATA'); // regular movie API call for trending movies
		await dispatch('GET_TRENDING_SHOWS_DATA'); // regular movie API call for trending shows
		await dispatch('GET_LIST_OF_MOVIES_CAST_DATA', 'trendingMovies'); // cast data for trending movies
		await dispatch('GET_LIST_OF_SHOWS_CAST_DATA', 'trendingShows'); // cast data for trending shows
		//dispatch('GET_MOVIE_GENRE_LIST'); // movie genres for search bar
		await dispatch('GET_TRENDING_MOVIE_WATCH_DATA'); // watch provider data
	},

	/**
	 * Used to load user data from Streamzy API
	 */

	async LOAD_INITIAL_USER_DATA ({dispatch}) {
		// this runs API call for detail info from OMDB as well
		dispatch('GET_MY_MOVIE_FAVORITES');
		// TODO:
		// fetch cast data for favs from tbdm // action exists 

		// MY API FOR FRIENDS LIST
		dispatch('GET_MY_FRIENDS_LIST');
		dispatch('GET_MY_PENDING_FRIEND_REQUESTS');
		// MY API FOR SHARED MOVIES
		dispatch('GET_MY_SHARES');
		// OMDB API FOR SHARED MOVIE DATA
		dispatch('GET_MY_SHARED_MOVIE_DATA');
		// MY API FOR NOTIFICATIONS
		dispatch('GET_MY_NOTIFICATIONS');
		// MY API FOR MATCHES
		dispatch('GET_MY_MATCHES');
		// OMDB FOR MATCHED MOVIE DATA
		dispatch('GET_MY_MATCH_MOVIE_DATA');
		
	},

	/* TMDB API CALLS */

	async GET_MOVIE_DETAIL_VIEW ({commit, dispatch}) { // TODO: Update this??? Need movie instead of id
		let movie = null;
		let { id, media } = state.movieDetailView;
		if (media === 'movie') {
			movie = state.trendingMovies.find(movie => movie.id === id);
		} else if (media === 'tv') {
			movie = state.trendingShows.find(show => show.id === id);
		} else {
			console.log('Unsupported movie detail media type')
		}
		if (!movie) { // movie is not found in trending movies/shows, most likely from search results
			movie = {
				movie_id: id,
				media_type: media
			}
		}
		const movieData = await dispatch('GET_MOVIE_DETAIL_DATA', movie);
		commit('SET_MOVIE_DETAIL_DATA', movieData);
		if (media === 'movie') {
			await dispatch('GET_MOVIE_CAST_DATA', id);
		} else {
			await dispatch('GET_SHOW_CAST_DATA', id);
		}
	},

	/* eslint-disable-next-line */
	async GET_MOVIE_DETAIL_DATA ({commit}, movie) {
		const apiKey = this.state.tmdbAPIKey;
		const id = movie.movie_id || movie.id;
		let res;
		if (movie.media_type == 'movie') {
			res = await axios.get(`https://api.themoviedb.org/3/movie/${id}?api_key=${apiKey}&language=en-US`);
		} else if (movie.media_type == 'tv') {
			res = await axios.get(`https://api.themoviedb.org/3/tv/${id}?api_key=${apiKey}&language=en-US`);
		} else {
			console.log('Unsupported media type ', movie);
		}
		// setting this so the media_type is available in state.movieDetailData
		if (!res.data.media_type) {
			res.data.media_type = movie.media_type;
		}
		return res.data;
	},
	
	async GET_MOVIE_CAST_DATA ({commit}, id) {
		const apiKey = state.tmdbAPIKey;
		const res = await axios.get(`https://api.themoviedb.org/3/movie/${id}/credits?api_key=${apiKey}&language=en-US`);
		// console.log('movie cast ', res.data);
		commit('SET_MOVIE_CREDITS_DATA', res.data);
	},

	async GET_SHOW_CAST_DATA ({commit}, id) {
		const apiKey = state.tmdbAPIKey;
		const res = await axios.get(`https://api.themoviedb.org/3/tv/${id}/credits?api_key=${apiKey}&language=en-US`);
		commit('SET_MOVIE_CREDITS_DATA', res.data);
	},

	async GET_MOVIE_GENRE_LIST ({commit}) {
		const apiKey = state.tmdbAPIKey;
		const res = await axios.get(`https://api.themoviedb.org/3/genre/movie/list?api_key=${apiKey}&language=en-US`);
		// console.log(res.data.genres);
		commit('SET_MOVIE_GENRE_LIST', res.data.genres);
	},
	/* eslint-disable-next-line */
	async SEARCH_BY_GENRE_SELECTION({commit}, searchParams) {
		console.log('search by genre selection')
		/* eslint-disable-next-line */
		const apiKey = state.tmdbAPIKey;
		/* eslint-disable-next-line */
		const { genre, genreID, media, services, sortBy } = searchParams;
		const url = `https://api.themoviedb.org/3/discover/movie?api_key=${apiKey}&language=en-US&sort_by=${sortBy}&include_adult=false&include_video=false&page=1&with_genres=${genreID}&with_watch_providers=${services}`;
		// console.log(url);
		const res = await axios.get(url);
		// console.log('search ', res.data.results);
		commit('SET_SEARCH_RESULTS', res.data.results);
	},

	async SEARCH_MOVIES_BY_USER_SEARCH ({commit}, userSearch) {
		const apiKey = state.tmdbAPIKey;
		const url = `https://api.themoviedb.org/3/search/movie?api_key=${apiKey}&language=en-US&query=${userSearch}&page=1&include_adult=false`;
		const res = await axios.get(url);
		const results = res.data.results.map(movie => {
			movie.media_type = 'movie';
			return movie;
		});
		commit('SET_SEARCH_RESULTS', results);
	},

	async SEARCH_TV_BY_USER_SEARCH ({commit}, userSearch) {
		const apiKey = state.tmdbAPIKey;
		const url = `https://api.themoviedb.org/3/search/tv?api_key=${apiKey}&language=en-US&query=${userSearch}&page=1&include_adult=false`;
		const res = await axios.get(url);
		const results = res.data.results.map(tv => tv.media_type = 'tv');
		commit('SET_SEARCH_RESULTS', results);
	},

	async SEARCH_MULTI_BY_USER_SEARCH ({commit}, userSearch) {
		const apiKey = state.tmdbAPIKey;
		const url = `https://api.themoviedb.org/3/search/multi?api_key=${apiKey}&language=en-US&query=${userSearch}&page=1&include_adult=false`;
		const res = await axios.get(url);

		commit('SET_SEARCH_RESULTS', res.data.results);
	},

	/* eslint-disable-next-line */
	async GET_MOVIE_WATCH_PROVIDER ({commit, dispatch}, id) {
		// /movie/379686/watch/providers?api_key={{api_key}}&language=en-US
		const apiKey = state.tmdbAPIKey
		const res = await axios.get(`https://api.themoviedb.org/3/movie/${id}/watch/providers?api_key=${apiKey}&language=en-US`);
		// console.log(res.data.results.US?.flatrate[0])
		if (res.data.results.US?.flatrate) {
			return res.data.results.US.flatrate[0];
		} else {
			return null;
		}
		// commit(res.data.results['US']['flatrate'][0])
	},

	/* MOVIE DATA FROM TMDB */
	async GET_TRENDING_MOVIES ({commit}) {
		const res = await axios.get('https://api.themoviedb.org/3/trending/movie/week?api_key=' + state.tmdbAPIKey);
		commit('SET_TRENDING_MOVIES', res.data.results);
	},

	async GET_TRENDING_MOVIE_DATA ({commit, dispatch}) {
		for (let i = 0; i < state.trendingMovies.length; i++) {
			const res = await dispatch('GET_MOVIE_DETAIL_DATA', state.trendingMovies[i]);
			commit('SET_MOVIE_DETAIL_DATA', res);
		}
	},

	async GET_TRENDING_SHOWS ({commit}) {
		const res = await axios.get('https://api.themoviedb.org/3/trending/tv/week?api_key=' + state.tmdbAPIKey);
		commit('SET_TRENDING_SHOWS', res.data.results);
	},

	async GET_TRENDING_SHOWS_DATA ({commit, dispatch}) {
		for (let i = 0; i < state.trendingShows.length; i++) {
			const res = await dispatch('GET_MOVIE_DETAIL_DATA', state.trendingShows[i]);
			commit('SET_MOVIE_DETAIL_DATA', res);
		}
	},

	/* LIST OF MOVIES/SHOWS CAST DATA */
	async GET_LIST_OF_MOVIES_CAST_DATA ({dispatch}, list) {
		// console.log('get list of movie cast ', list);
		const movieList = this.state[list];
		for (let i = 0; i < movieList.length; i++) {
			let id = movieList[i].id;
			await dispatch('GET_MOVIE_CAST_DATA', id);
		}
	},

	async GET_LIST_OF_SHOWS_CAST_DATA ({dispatch}, list) {
		// console.log('get list of show cast ', list);
		const movieList = this.state[list];
		for (let i = 0; i < movieList.length; i++) {
			let id = movieList[i].id;
			await dispatch('GET_SHOW_CAST_DATA', id);
		}
	},

	/* eslint-disable-next-line */
	async GET_TRENDING_MOVIE_WATCH_DATA ({commit, dispatch}) {
		for (let i = 0; i < state.trendingMovies.length; i++) {
			const provider = await dispatch('GET_MOVIE_WATCH_PROVIDER', state.trendingMovies[i].id);
			if (provider) {
				provider.movie_id = state.trendingMovies[i].id;
				commit('SET_MOVIE_WATCH_PROVIDER', provider)
			}
			
		}
	},
	/* USER WATCH LIST */

	async ADD_MOVIE_TO_WATCH_LIST ({dispatch}, movie) {
		await axios.post(process.env.VUE_APP_API_URL + '/favorites', { 
			userID: state.user.id, 
			movieID: movie.id, 
			mediaType: movie.media
		});
		dispatch('GET_MY_MOVIE_FAVORITES');
	},

	async MARK_MOVIE_AS_WATCHED ({dispatch}, movie) {
		await axios.put(process.env.VUE_APP_API_URL + '/favorites', { 
			userID: state.user.id, 
			movieID: movie.id, 
			mediaType: movie.media,
			status: 'watched'
		});
		dispatch('GET_MY_MOVIE_FAVORITES');
	},

	async REMOVE_MOVIE_FROM_WATCH_LIST ({dispatch}, movie) {
		await axios.put(process.env.VUE_APP_API_URL + '/favorites', { 
			userID: state.user.id, 
			movieID: movie.id, 
			mediaType: movie.media,
			status: 'removed'
		});
		dispatch('GET_MY_MOVIE_FAVORITES');
	},

	async GET_MY_MOVIE_FAVORITES ({commit, dispatch}) {
		// console.log('getting my movie list')
		const res = await axios.get(process.env.VUE_APP_API_URL + '/favorites?userID=' + state.user.id);
		// console.log(res.data);
		commit('CLEAR_USER_MOVIE_LIST'); // TODO: Probably don't really need this?
		commit('SET_USER_MOVIE_LIST', res.data);
		commit('CLEAR_POPULATED_MOVIE_LIST');
		// TODO: Does it make sense to have this auto-called here??
		for (let i = 0; i < state.userMovieFavorites.length; i++) { 
			let movieData = await dispatch('GET_MOVIE_DETAIL_DATA', state.userMovieFavorites[i]);
			commit('SET_POPULATED_MOVIE_LIST_DATA', movieData);
		}
		// TODO: Need to get cast data for state.populatedMovieFavorites
	},

	/* FRIENDS LIST */
	async GET_MY_FRIENDS_LIST ({commit}) {
		const res = await axios.get(process.env.VUE_APP_API_URL + '/friends?userID=' + state.user.id);
		res.data = res.data.map(item => {
			const first = item.email.split('@')[0];
			const initials = first.split('.');
			item.initials = initials.map(item => item.substr(0, 1).toUpperCase()).join('')
			return item;
		});
		commit('SET_FRIENDS_LIST', res.data);
	},

	async SEND_NEW_FRIEND_REQUEST ({dispatch}, email) {
		const body = {
			userEmail: state.user.email,
			userID: state.user.id,
			inviteEmail: email
		}
		await axios.post(process.env.VUE_APP_API_URL + '/friend-requests', body);
		await dispatch('GET_MY_SENT_FRIEND_REQUESTS');
	},

	async GET_MY_PENDING_FRIEND_REQUESTS ({commit}) {
		const res = await axios.get(process.env.VUE_APP_API_URL + '/pending-friend-requests?userID=' + state.user.id);
		commit('SET_PENDING_REQUESTS', res.data);
	},

	async UPDATE_FRIEND_REQUEST ({dispatch}, update) { 
		await axios.put(process.env.VUE_APP_API_URL + '/friend-requests', update);
		// console.log(res.data);
		await dispatch('GET_MY_FRIENDS_LIST');
		await dispatch('GET_MY_PENDING_FRIEND_REQUESTS');
	},

	async GET_MY_SENT_FRIEND_REQUESTS ({commit}) {
		const res = await axios.get(process.env.VUE_APP_API_URL + '/sent-friend-requests?userID=' + state.user.id);
		commit('SET_SENT_REQUESTS', res.data);
	},

	/* MOVIE SHARES */

	/**
	 * Get Shares data from my API
	 * If this is called, GET_MY_SHARED_MOVIE_DATA must be called as well
	 */
	async GET_MY_SHARES ({commit}) {
		// console.log('getting shares');
		const res = await axios.get(process.env.VUE_APP_API_URL + '/my-shares?userID=' + state.user.id);
		// console.log('shares ', res.data);
		const normalizedData = normalize(res.data, shareSchema);
		commit('UPDATE_ENTITIES', { entities: normalizedData.entities });
		commit('UPDATE_RESULTS', { results: { shares: normalizedData.result }});
	},

	async SEND_NEW_MOVIE_SHARE ({commit, dispatch}) {
		commit('SET_SHARE_SENT', false);
		const body = {
			"share_from": state.user.id,
			"share_from_email": state.user.email,
			"share_to_email": state.currentShare.share_to_email,
			"movie_id": state.currentShare.movie_id,
			"media_type": state.currentShare.media_type,
			"comments": state.currentShare.share_from_comments,
			"excite_level": state.currentShare.share_from_excite
		}
		await axios.post(process.env.VUE_APP_API_URL + '/shares', body);
		commit('SET_SHARE_SENT', true);
		dispatch('GET_MY_SHARES');
		dispatch('GET_MY_MOVIE_FAVORITES');
		dispatch('GET_MY_SHARED_MOVIE_DATA');
	},

	async GET_MY_SHARED_MOVIE_DATA ({commit, dispatch}) {
		// console.log('getting shared movie data')
		for (let i = 0; i < state.results.shares.length; i++) {
			let curMovie = state.entities.shares[state.results.shares[i]]
			let res = await dispatch('GET_MOVIE_DETAIL_DATA', curMovie);
			res.str_id = state.results.shares[i];
			commit('SET_POPULATED_INCOMING_SHARE_DATA', res);
		}
		for (let j = 0; j < state.results.shares.length; j++) {
			/* eslint-disable-next-line */
			let castRes;
			let curMovie = state.entities.shares[state.results.shares[j]]
			if (curMovie.media_type == 'movie') {
				castRes = await dispatch('GET_MOVIE_CAST_DATA', curMovie.movie_id);
			} else if (curMovie.media_type == 'tv') {
				castRes = await dispatch('GET_SHOW_CAST_DATA', curMovie.movie_id);
			} else {
				console.log('Unsupported media type in shares', curMovie.movie_id);
			}
		}
	},

	async ADD_NEW_COMMENT_TO_SHARED_MOVIE ({dispatch}, comment) { 
		// console.log('comment added ', comment);
		await axios.post(process.env.VUE_APP_API_URL + '/comment', comment);
		await dispatch('GET_MY_SHARES');
	},
	
		/* MOVIE MATCHES */

	async GET_MY_MATCHES ({commit}) {
		const res = await axios.get(process.env.VUE_APP_API_URL + '/matches?userID=' + state.user.id);
		// console.log(res.data);
		commit('SET_INCOMING_MATCHES', res.data);
	},

	async GET_MY_MATCH_MOVIE_DATA ({dispatch, commit}) {
		commit('CLEAR_POPULATED_MOVIE_MATCHES');
		for (let i = 0; i < state.incomingMatches.length; i++) {
			let curMovie = state.incomingMatches[i];
			let res = await dispatch('GET_MOVIE_DETAIL_DATA', curMovie);
			res.str_id = state.incomingMatches[i].id;
			res.match_email = state.incomingMatches[i].match_email;
			res.user_email = state.incomingMatches[i].user_email;
			commit('SET_POPULATED_MATCH_DATA', res);
		}
	},

	async MARK_MATCH_AS_WATCHED ({dispatch}, id) {
		await axios.put(process.env.VUE_APP_API_URL + '/matches', {
			matchID: id,
			status: 'watched'
		});
		await dispatch('GET_MY_MATCHES');
		await dispatch('GET_MY_MATCH_MOVIE_DATA');
	},

	async MARK_MATCH_AS_REMOVED ({dispatch}, id) {
		await axios.put(process.env.VUE_APP_API_URL + '/matches', {
			matchID: id,
			status: 'removed'
		});
		await dispatch('GET_MY_MATCHES');
		await dispatch('GET_MY_MATCH_MOVIE_DATA');
	},

	/*  NOTIFICATIONS  */

	async GET_MY_NOTIFICATIONS ({commit}) {
		// console.log('notifications');
		const res = await axios.get(process.env.VUE_APP_API_URL + '/notifications?userID=' + state.user.id);
		// console.log(res.data);
		commit('SET_USER_NOTIFICATIONS', res.data);
	},

	async MARK_NOTIFICATION_READ ({dispatch}, id) {
		await axios.put(process.env.VUE_APP_API_URL + '/notifications?userID=' + state.user.id + '&notificationID=' + id);
		dispatch('GET_MY_NOTIFICATIONS');
	},

	/* eslint-disable-next-line */
	async MARK_ALL_NOTIFICATIONS_READ ({dispatch, getters}) {
		const ids = getters.getAllNewNotificationIDs;
		// console.log(ids);
		await axios.put(process.env.VUE_APP_API_URL + '/notifications?userID=' + state.user.id + '&notificationID=' + ids);
		dispatch('GET_MY_NOTIFICATIONS');
	}
	

};

export default actions;