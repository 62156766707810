<template>
	<v-tab-item>
		<v-card-title>
			It's not you, it's us. (But also you)
		</v-card-title>
		<v-card-text>
			<div>The magical elves that run Streamzy are telling us you have to be logged in to do that. You can create 
				a free account here in about 10 seconds (or less if you type really fast!).
			</div>
			<v-form v-model="isRegisterValid">
				<v-row>
					<v-col>
						<v-text-field label="First Name" v-model="firstName"
						:rules="[rules.required]" @input="setUserFirstName"></v-text-field>
					</v-col>
					<v-col>
						<v-text-field label="Last Name" v-model="lastName"
						:rules="[rules.required]" @input="setUserLastName"></v-text-field>
					</v-col>
				</v-row>
				<v-row class="mb-1">
					<v-col>
						<v-text-field label="Email" v-model="email"
						:rules="[rules.required, rules.email]" @input="setUserEmail"></v-text-field>
					</v-col>
					<v-col>
						<v-text-field label="Password" v-model="password"
						:rules="[rules.required]" @input="setUserPassword"
						:append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
						:type="show ? 'text' : 'password'" @click:append="show = !show"></v-text-field>
					</v-col>
				</v-row>
			</v-form>
			<v-divider></v-divider>
		</v-card-text>
		<v-card-actions>
			
			<v-spacer></v-spacer>
			<!-- <v-btn depressed color="error" @click="closeModal">Close</v-btn> -->
			<v-btn depressed color="primary" :disabled="!isRegisterValid"
				@click="registerAccount">Create Account</v-btn>
		</v-card-actions>
	</v-tab-item>
</template>

<script>
export default {
	data: () => ({
		rules: {
			required: value => !!value || 'Required.',
			counter: value => value.length <= 20 || 'Max 20 characters',
			email: value => {
				const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				return pattern.test(value) || 'Invalid e-mail.'
			},
			
		},
		isRegisterValid: false,
		email: null,
		password: null,
		firstName: null,
		lastName: null,
		show: false,
	}),
	methods: {
		closeModal () {
			this.$store.commit('TOGGLE_ACCOUNT_MODAL', false);
		},
		setUserEmail () {
			this.$store.commit('SET_USER_EMAIL', this.email.trim().toLowerCase());
		},
		setUserPassword () {
			this.$store.commit('SET_USER_PASSWORD', this.password);
		},
		setUserFirstName () {
			let firstName = this.firstName.trim().slice(0, 1).toUpperCase() + this.firstName.trim().slice(1).toLowerCase();
			this.$store.commit('SET_USER_FIRST_NAME', firstName);
		},
		setUserLastName () {
			let lastName = this.lastName.trim().slice(0, 1).toUpperCase() + this.lastName.trim().slice(1).toLowerCase();
			this.$store.commit('SET_USER_LAST_NAME', lastName);
		},
		async registerAccount () {
			localStorage.setItem('email', this.email);
			localStorage.setItem('password', this.password);
			await this.$store.dispatch('CREATE_NEW_ACCOUNT');
			setTimeout(() => {
				this.email = '';
				this.password = '';
				this.firstName = '';
				this.lastName = '';
			}, 500);
		}

	},

}
</script>