<template>
	<v-row>
		<v-col cols="12" md="5">
			<v-row v-for="movie in movieList" :key="movie.id" class="ml-2 mb-2">
				<movie-detail-card :movie="movie" :cast="movieCast(movie)" :details="movie"></movie-detail-card>
			</v-row>
			<v-row v-if="!movieList.length">
				<v-col>
					<v-card>
						<v-card-title>
							No movies on your Watch List yet
						</v-card-title>
						<v-card-text>
							<p class="card-text">Your Watch List is where you can track movies and shows that you want to watch!</p>
							<p class="card-text">You can get started by creating a free account, and then adding movies and shows to your List.</p>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<create-account-button />
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
		</v-col>
	</v-row>
</template>

<script>
import MovieDetailCard from '../components/movies/MovieDetailCard.vue';
import CreateAccountButton from '../components/CreateAccountButton.vue'
export default {
	components: {
		MovieDetailCard,
		CreateAccountButton
	},
	data: () => ({

	}),
	methods: {
		setImageURL (path) {
			return 'https://image.tmdb.org/t/p/w500/' + path;
		},
		movieCast (movie) {
			const creditsData = this.$store.state.movieCreditsData.find(item => item.id == movie.id);
			if (creditsData) {
				return creditsData.cast.slice(0, 10);
			} else {
				return ''
			}
		},
		movieDetails (movie) {
			return this.$store.state.movieDetailData.find(item => item.id == movie.id);
		}
	},
	computed: {
		movieList () {
			return this.$store.state.populatedMovieFavorites;
		},
		
	},
	mounted () {
		
	}
}
</script>