<template>
	<v-row>
		<v-col cols="12" md="5">
			<movie-detail-card :movie="currentMovie || movieDetails" :cast="movieCast" :details="movieDetails"></movie-detail-card>
		</v-col>
	</v-row>
</template>

<script>
import MovieDetailCard from '../components/movies/MovieDetailCard.vue'

export default {
	components: {
		MovieDetailCard
	},
	data: () => ({

	}),
	methods: {
		setImageURL (path) {
			return 'https://image.tmdb.org/t/p/w500/' + path;
		},
		setMovieRating(rating) {
			return Math.floor(rating) / 2;
		},
	},
	computed: {
		movieDetailID () {
			return this.$route.query.id;
		},
		mediaType () {
			return this.$route.query.media;
		},
		currentMovie () {
			// TODO: This could be RF'ed to improve the API calls
			// Do not appear to need trending movie data for the movie prop if we have movieDetailData

			if (this.mediaType === 'movie') {
				return this.$store.state.trendingMovies.find(item => item.id == this.movieDetailID);
			} else if (this.mediaType === 'tv') {
				return this.$store.state.trendingShows.find(item => item.id == this.movieDetailID);
			} else {
				console.log('Error in MovieDetailView');
			}
			return '';
		},
		movieCast () {
			const creditsData = this.$store.state.movieCreditsData.find(item => item.id == this.movieDetailID);
			if (creditsData) {
				return creditsData.cast.slice(0, 10);
			} else {
				return '';
			}
		},
		movieDetails () {
			return this.$store.state.movieDetailData.find(item => item.id == this.movieDetailID);
		}
	},
	mounted () {
		this.$store.commit('SET_MOVIE_DETAIL_VIEW', { id: this.movieDetailID, media: this.mediaType });
		if (!this.currentMovie) { 
			this.$store.dispatch('GET_MOVIE_DETAIL_VIEW');
		}
	}
}
</script>