<template>
	<div>
		<home-splash />
		<movie-slider :movies="trendingMovies" :userID="userID" cardTitle="Trending Movies"
			title="title" media="movie"/>
		<movie-slider :movies="trendingShows" :userID="userID" cardTitle="Trending Shows" 
			title="name" media="tv"/>
	</div>
</template>

<script>
import MovieSlider from '../components/MovieSlider.vue';
import HomeSplash from '../components/home/HomeSplash.vue';

export default {
	components: { 
		MovieSlider, 
		HomeSplash,

	},
	data: () => ({

	}),
	methods: {

	},
	computed: {
		trendingMovies () {
			return this.$store.state.trendingMovies;
		},
		trendingShows () {
			return this.$store.state.trendingShows;
		},
		userID () {
			return this.$store.state.user.id;
		},
		
		
	},
	mounted () {

	}
}
</script>
