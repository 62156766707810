<template>
	<v-row>
    <v-col>
      <v-card>
        <v-card-title>About Streamzy</v-card-title>
        <v-card-text>
          <p class="card-header">Attribution</p>
          Some of the data for Streamzy is provided by the following services: <br />
          <a href="https://www.themoviedb.org/" target="_blank">The Movie Database</a>
          <p>Streamzy is not affiliated with or endorsed by TMDB.</p>
          <v-img :src="require('@/assets/tmdb.svg')" height="300" contain></v-img>

        </v-card-text>
        <v-card-actions>

        </v-card-actions>
      </v-card>
    </v-col>
    <v-col></v-col>
  </v-row>
</template>

<script>
export default {
	data: () => ({

	}),
	methods: {

	},
	computed: {

	},
	watch: {

	},
	mounted () {

	}
}
</script>

<style lang="scss" scoped>
  .card-header {
    font-weight: bold;
  }
</style>