import { DateTime } from 'luxon';

export default {
    findElapsedTime: function (ts) {
        const created = DateTime.fromISO(ts);
        const now = DateTime.now();
        const ago = now.diff(created, ['seconds', 'minutes', 'hours', 'days'])

        if (ago.days) {
            return ago.days == 1 ? ago.days + ' day ago' : ago.days + ' days ago';
        } else if (ago.hours) {
            return ago.hours == 1 ? ago.hours + ' hour ago' : ago.hours + ' hours ago';
        } else if (ago.minutes) {
            return ago.minutes == 1 ? ago.minutes + ' minute ago' : ago.minutes + ' minutes ago';
        } else {
            return 'Just now'
        }
    },
    getUserInitials: function () {
        return this.$store.state.user.firstName.slice(0,1).toUpperCase() + this.$store.state.user.lastName.slice(0,1).toUpperCase() || '';
    },
    setOneSignalExternalUserID: function (streamzyID) {
        this.$OneSignal.push(function () {
            this.$OneSignal.setExternalUserId(streamzyID);
        });
    },

}