<template>
    <div>
      <reset-password />
    </div>
</template>

<script>
import ResetPassword from '../components/password/ResetPassword.vue';

export default {
  components: { 
    ResetPassword,
  },
  data: () => ({

  }),
  methods: {

  },
  computed: {

  },
  watch: {

  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>

</style>