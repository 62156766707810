<template>
	<v-row class="mb-2" v-if="showSplash">
    <v-col cols="12">
      <v-sheet>
      <v-row>
        
        <v-col cols="12" md="6">
          <v-card height="100%" elevation="0">
            <v-card-title></v-card-title>
            <v-card-text>
              <v-img :src="require('../../assets/home-splash-cropped.jpg')" contain></v-img>

            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card height="100%" elevation="0">
            <v-card-title>Streamzy Makes It Easy To Find <br v-if="isOnMobileDevice"/>Trending Movies and Shows</v-card-title>
            <v-card-text>
              <p class="card-text">Here's how to get the most out of Streamzy:</p>
              <p class="card-text">1) Create a free account so you can save Movies and Shows to your Watch List</p>
              <p class="text-center"><create-account-button /></p>
              <p class="card-text">2) Invite a Friend so you can Share your movies with them</p>
              <p class="card-text">3) Browse the Trending Movies and Shows below</p>
              <p class="card-text">4) When you and a Friend match on a movie, Streamy will let you know!</p>
              <v-sheet class="lighter-background">
                <blockquote class="blockquote text-center">
                  <em>{{quote.quote}}<br v-if="isOnMobileDevice"/>{{quote.speaker}}</em>
                </blockquote>
              </v-sheet>
              <br />
              <br />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" outlined absolute bottom right @click="dismissSplash">Dismiss</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      
      </v-row>
      </v-sheet>
    </v-col>
    
    
  </v-row>
</template>

<script>
import CreateAccountButton from '../CreateAccountButton.vue';

export default {
  components: {
    CreateAccountButton,

  },
	data: () => ({
    showSplash: true,
    quote: '',
    quotesPool: [
      { quote: `Come with me if you want to live. `, speaker: `- Terminator` },
      { quote: `AAAAAHHHHHHHHH! `, speaker: `- Kevin McAllister` },
      { quote: `It's just a flesh wound! `, speaker: `- The Black Knight` },
      { quote: `So you're saying there's a chance... `, speaker: `- Lloyd Christmas` },
      { quote: `I'm in a glass case of emotion! `, speaker: `- Ron Burgundy` },
    ]
	}),
	methods: {
    dismissSplash () {
      this.showSplash = false;
      // console.log('hiding');
      localStorage.setItem('hideSplash', true);
    },
    chooseQuote () {
      const index = Math.floor(Math.random() * this.quotesPool.length);
      this.quote = this.quotesPool[index];
    },
    toggleDisplaySplash () {
      const val = localStorage.getItem('hideSplash') == 'true';
      // console.log(`val is ${val}`);
      if (val && val == true) {
        // console.log('setting to false, hide');
        this.showSplash = false;
      }
    }
	},
	computed: {
    isOnMobileDevice (){
			return this.$vuetify.breakpoint.mobile;
		}
	},
	watch: {

	},
	mounted () {
    this.toggleDisplaySplash();
    this.chooseQuote();
	}
}
</script>

<style lang="scss" scoped>
  .lighter-background {
    background-color: #555555;
    border-radius: 5px;
  }
</style>