<template>
  <v-card>
    <!-- This throws an error when used in search results 
      - Cannot read properties of undefined (reading 'backdrop_path')
      which is strange because the image displays properly
    -->
    <v-img :src="setImageURL(movie.backdrop_path)" contain></v-img>
    <v-card-title
      >{{ movie.title ? movie.title.length > 25 ? movie.title.slice(0, 25) + '...' : movie.title : 
        movie.name.length > 25 ? movie.name.slice(0, 25) + '...' : movie.name }}
      <v-spacer></v-spacer>
      <!-- MOVIE ACTION MENU HERE -->
      <movie-action-menu :movie="movie"/>

    </v-card-title>
    <v-row align="center" justify="start" class="mb-2 ml-2">
      <v-rating
        :value="setMovieRating(movie.vote_average)"
        color="amber"
        dense
        half-increments
        readonly
        size="14"
      ></v-rating>

      <div class="grey--text ms-4">
        {{ movie.vote_average }} ({{ movie.vote_count }})
      </div>
    </v-row>
    <v-tabs v-model="tab" grow>
      <v-tab>Plot</v-tab>
      <!-- <v-tab>Trailer</v-tab> -->
      <v-tab>Cast</v-tab>
      <!-- <v-tab>Details</v-tab>
			<v-tab>Poster</v-tab> -->
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <plot :plot="movie.overview"></plot>
      </v-tab-item>
      <!-- <v-tab-item>
        <movie-trailer movieKey="Fp9pNPdNwjI"></movie-trailer>
      </v-tab-item> -->
      <v-tab-item>
        <cast :cast="cast"></cast>
      </v-tab-item>
      <!-- <v-tab-item>
				<movie-details :genres="details.genres" :homepage="details.homepage"
					:releaseDate="details.release_date" :runTime="details.runtime"></movie-details>
			</v-tab-item>
			<v-tab-item>
				<poster :url="setImageURL(movie.poster_path)"></poster>
			</v-tab-item> -->
    </v-tabs-items>
    <!-- <v-card-text>{{movie.overview}}</v-card-text> -->
    <v-card-actions>
    </v-card-actions>
  </v-card>
</template>

<script>
import Plot from "../movie-detail/Plot.vue";
import Cast from "../movie-detail/Cast.vue";
// import FriendsList from './shares/FriendsList.vue';
import MovieActionMenu from './MovieActionMenu.vue';
// import MovieTrailer from './trailers/MovieTrailer.vue';
// import MovieDetails from './movie-detail/DetailsTab.vue';
// import Poster from '../components/movie-detail/Poster.vue';

export default {
  props: ["movie", "cast", "details"],
  components: {
    Plot,
    Cast,
    MovieActionMenu,
    // MovieTrailer
    // MovieDetails,
    // Poster
  },
  data: () => ({
    tab: "plot",
    tabItems: ["plot", "cast"],
  }),
  methods: {
    setImageURL(path) {
      if (path) {
        return "https://image.tmdb.org/t/p/w500/" + path;
      }
    },
    setMovieRating(rating) {
      return Math.floor(rating) / 2;
    },
    addToWatchList() {
      if (this.userID) {
        this.$store.dispatch("ADD_MOVIE_TO_WATCH_LIST", {
          id: this.movieDetailID,
          media: this.mediaType,
        });
      } else {
        this.$store.commit("TOGGLE_ACCOUNT_MODAL", true);
      }
    },
    shareMovie() {
      if (this.userID) {
        this.$store.commit("SET_CURRENT_SHARE_MOVIE", {
          id: this.movieDetailID,
          media: this.mediaType,
        });
      } else {
        this.$store.commit("TOGGLE_ACCOUNT_MODAL", true);
      }

    },
    movieIsOnWatchList(id) {
      return !!this.$store.state.userMovieFavorites.find(
        (movie) => movie.movie_id == id
      );
    },
  },
  computed: {
    isOnMobileDevice() {
      return this.$vuetify.breakpoint.mobile;
    },
    userID() {
      return this.$store.state.user.id;
    },
    movieDetailID() {
      return this.$route.query.id;
    },
    mediaType() {
      return this.$route.query.media;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>