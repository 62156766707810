<template>
	<div>
		<!-- {{movieMatches}} -->
		<v-row>
			<v-col cols="12" md="5">
				<v-list two-line v-if="movieMatches.length">
					<template v-for="movie in movieMatches">
						<v-list-item :key="movie.id" id="movie.str_id">
							<v-list-item-avatar>
								<v-img :src=setImageURL(movie.backdrop_path)></v-img>
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title>{{movie.title || movie.name}}</v-list-item-title>
								<v-list-item-subtitle>Matched with: {{movie.match_email === userEmail ? movie.user_email : movie.match_email}}</v-list-item-subtitle>
							</v-list-item-content>
							<v-list-item-action>
								<v-menu offset-y>
									<template v-slot:activator="{ on, attrs }">
										<v-btn icon color="primary" 
											v-bind="attrs" v-on="on">
											<v-icon>mdi-dots-vertical</v-icon>
										</v-btn>
									</template>
									<v-list>
										<v-list-item v-for="(item, index) in movieMatchActions" :key="index" :id="item.actionID">
											<v-list-item-action>
												<v-icon :color="item.iconColor">{{item.icon}}</v-icon>
											</v-list-item-action>
											<v-list-item-title @click="handleMatchAction($event, movie.str_id)">{{ item.title }}</v-list-item-title>
										</v-list-item>
									</v-list>
								</v-menu>
							</v-list-item-action>
						</v-list-item>
					</template>
				</v-list>
				<div v-else>
					<v-col cols="12">
						<v-card>
							<v-card-title> No Matches Found Yet </v-card-title>
							<v-card-text>
								<p class="card-text">Matches are created automatically by Streamzy, when both you and a Friend are interested in the same movie.</p>
								<p class="card-text">You can get started by creating a free account, and adding some a friend or two. Then check back here to find your Matches!</p>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<create-account-button />
							</v-card-actions>
						</v-card>
					</v-col>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import CreateAccountButton from '../components/CreateAccountButton.vue';

export default {
	components: {
		CreateAccountButton,
	},
	data: () => ({
		movieMatchActions: [
			{ actionID: 101, title: 'Mark as Watched', icon: 'mdi-star', iconColor: 'primary' },
			{ actionID: 102, title: 'Remove from Match List', icon: 'mdi-close-circle-outline', iconColor: 'red' }
		]
	}),
	methods: {
		setImageURL (path) {
			return "https://image.tmdb.org/t/p/w500" + path;
		},
		handleMatchAction (event, str_id) {
			let id = null;
			let x = 0;
			while (!id) {
				id = event.path[x].id;
				x++;
			}
			switch(Number(id)) {
				case 101: 
					this.markMovieAsWatched(str_id);
					break;
				case 102: 
					this.removeMovieFromWatchList(str_id);
					break;
				default:
					break;
			}
		},
		markMovieAsWatched (str_id) {
			this.$store.dispatch('MARK_MATCH_AS_WATCHED', str_id);
		},
		removeMovieFromWatchList (str_id) {
			this.$store.dispatch('MARK_MATCH_AS_REMOVED', str_id);
		}
	},
	computed: {
		movieMatches () {
			return this.$store.state.populatedMatches;
		},
		userEmail () {
			return this.$store.state.user.email;
		}
	},
	watch: {

	},
	async mounted () {
		await this.$store.dispatch('GET_MY_MATCHES');
		await this.$store.dispatch('GET_MY_MATCH_MOVIE_DATA');
	}
}
</script>

<style lang="scss" scoped>

</style>