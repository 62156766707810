<template>
  <div>
    <div v-if="incomingSharedMovies.length">
      <v-row
        v-for="movie in incomingSharedMovies"
        :key="movie.str_id"
        class="ml-2"
      >
        <v-col cols="12" md="5" offset-md="1">
          <!-- 5 cols looks better than 6 -->
          <movie-detail-card
            :movie="findShareMovieDetails(movie.movie_id)"
            :cast="movieCast(movie.movie_id)"
            :details="findShareMovieDetails(movie.movie_id)"
          >
          </movie-detail-card>
        </v-col>
        <v-col md="5">
          <v-card class="mt-2" :id="movie.str_id">
            <!-- <v-card-title>{{findShareInfo(movie).share_from_email}}</v-card-title> -->
            <v-card-text>
              <v-list light three-line>
                <v-list-item
                  v-for="comment in getComments(movie.str_id)"
                  :key="comment.str_id"
                >
                  <v-list-item-avatar :color="findIconColor(comment.email)">
                    {{ findInitials(comment.email) }}
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ comment.comment }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ comment.email }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ findElapsedTime(comment.created_at) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon>
                          <v-icon color="primary" x-large>
                            {{ findExciteIcon(comment.excite_level).icon }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{
                        findExciteIcon(comment.excite_level).message
                      }}</span>
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>
                <v-subheader>
                  Shared by
                  {{ getCurrentShare(movie.str_id).share_from_email }} <br />
                  to {{ getCurrentShare(movie.str_id).share_to_email }}
                  {{
                    findElapsedTime(getCurrentShare(movie.str_id).created_at)
                  }}
                </v-subheader>
              </v-list>
            </v-card-text>
            <v-card-actions>
              <v-card-actions>
                <v-btn color="primary" outlined @click="noToShare($event)"
                  >Nah</v-btn
                >
                <v-btn color="primary" @click="yesToShare($event)"
                  >I'm In!</v-btn
                >
                <v-btn color="secondary" @click="commentOnShare">Comment</v-btn>
              </v-card-actions>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-col cols="12" md="5">
        <v-card>
          <v-card-title>No Shares Found Yet</v-card-title>
          <v-card-text>
            <p class="card-text">When you find a movie you want to watch, you can Share it with a Friend that you'd like to watch it with, to see if they're interested.</p>
            <p class="card-text">You can get started by creating a free account, and then adding some friends.</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <create-account-button />
          </v-card-actions>
        </v-card>
      </v-col>
    </div>
  </div>
</template>

<script>
import library from "../library/library";
import MovieDetailCard from "../components/movies/MovieDetailCard.vue";
import CreateAccountButton from '../components/CreateAccountButton.vue';

export default {
  components: {
    /* eslint-disable-next-line */
    MovieDetailCard,
    CreateAccountButton,

  },
  data: () => ({}),
  methods: {
    setImageURL(path) {
      return "https://image.tmdb.org/t/p/w500/" + path;
    },
    movieCast(id) {
      // console.log('movie', movie)
      const creditsData = this.$store.state.movieCreditsData.find(
        (item) => item.id == id
      );
      if (creditsData) {
        return creditsData.cast.slice(0, 10);
      } else {
        // console.log('credits not found' + JSON.stringify(movie, null, 4))

        return "";
      }
    },
    movieDetails(movie) {
      return this.$store.state.movieDetailData.find(
        (item) => item.id == movie.id
      );
    },

    yesToShare(event) {
      let id = null;
      let x = 0;
      while (!id) {
        id = event.path[x].id;
        x++;
      }
      // console.log(id);
      const newComment = {
        share_id: id,
        comment: "Looks good!",
        excite_level: 1,
        email: this.user.email,
        userID: this.user.id,
      };
      // console.log('adding ', newComment);
      this.$store.dispatch("ADD_NEW_COMMENT_TO_SHARED_MOVIE", newComment);
    },
    noToShare(event) {
      let id = null;
      let x = 0;
      while (!id) {
        id = event.path[x].id;
        x++;
      }
      // console.log(id);
      const newComment = {
        share_id: id,
        comment: "Nah",
        excite_level: 0,
        email: this.user.email,
        userID: this.user.id,
      };
      // console.log('adding ', newComment);
      this.$store.dispatch("ADD_NEW_COMMENT_TO_SHARED_MOVIE", newComment);
    },
    commentOnShare(event) {
      let id = null;
      let x = 0;
      while (!id) {
        id = event.path[x].id;
        x++;
      }
      // console.log(id);
      let curShare = this.getCurrentShare(id);
      console.log(curShare);
      this.$store.commit("SET_CURRENT_RESPONSE_ID", id);
      this.$store.commit("SET_CURRENT_RESPONSE_MOVIE", {
        id: curShare.movie_id,
        media: curShare.media_type,
      });
      this.$store.commit(
        "SET_CURRENT_RESPONSE_FRIEND",
        curShare.share_from_email
      );
      this.$store.commit("SHOW_RESPOND_SHARE_MODAL");
    },
    findInitials(email) {
      if (!email) {
        email = this.userEmail || "";
      }
      const first = email.split("@")[0];
      let initials = first
        .split(".")
        .map((item) => item.substr(0, 1).toUpperCase())
        .join("");
      // initials = initials.map(item => item.substr(0, 1).toUpperCase()).join('');
      return initials;
    },
    findIconColor(email) {
      return email === this.userEmail ? "accent" : "primary";
    },
    findExciteIcon(level) {
      switch (level) {
        case 0:
          return { icon: "mdi-emoticon-neutral-outline", message: "Mehh??" };
        case 1:
          return { icon: "mdi-emoticon-happy-outline", message: "Looks Good!" };
        case 2:
          return {
            icon: "mdi-emoticon-excited-outline",
            message: "Can't Wait!",
          };
        default:
          return { icon: "mdi-emoticon-happy-outline", message: "Looks Good!" };
      }
    },
    getComments(id) {
      return this.$store.getters.shareComments(id);
    },
    findShareMovieDetails(id) {
      // returns movie data, like title, cast, etc
      return this.$store.getters.findMovieDetails(id);
    },
    getCurrentShare(id) {
      return this.$store.getters.findShare(id);
    },
  },
  computed: {
    incomingSharedMovies() {
      return this.$store.getters.sharedMovies;
    },

    user() {
      return this.$store.state.user;
    },
  },
  created() {
    this.findElapsedTime = library.findElapsedTime;
  },
	async mounted () {
		await this.$store.dispatch('GET_MY_SHARES');
		await this.$store.dispatch('GET_MY_SHARED_MOVIE_DATA');
	}
};
</script>