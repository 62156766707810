<template>
	<div>
		<v-list color="white" light v-if="friendsList.length">
			<v-list-item v-for="friend in friendsList" :key="friend.email" :id="friend.email" 
				@click="selectFriend($event)">
				<v-list-item-avatar color="primary" dark>
					{{friend.initials}}
				</v-list-item-avatar>
				<v-list-item-content>
					<v-list-item-title>
						{{friend.first_name}} : {{friend.email}}
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
		<div v-else>No friends found. Why not add some?</div>
	</div>
	
</template>

<script>
export default {
	data: () => ({

	}),
	methods: {
		selectFriend (event) {
			let id = null;
			let x = 0;
			while (!id) {
				id = event.path[x].id;
				x++;
			}
			// console.log(id)
			this.$store.commit('SET_CURRENT_SHARE_FRIEND', id);
		}
	},
	computed: {
		friendsList () {
			return this.$store.state.friendsList;
		}
	}
}
</script>