<template>
	<v-card>
		<v-card-title>Sent Friend Requests</v-card-title>
		<v-card-text>
			<v-list v-if="friendRequests.length" two-line>
				<v-list-item v-for="request in friendRequests" :key="request.id" :id="request.id">
					<v-list-item-content>
						<v-list-item-title>
							Sent To: {{request.requestee_email}}
						</v-list-item-title>
						<v-list-item-subtitle>
							Invite Sent: {{new Date(request.created_at).toDateString()}}
						</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<div v-else>Nothing here at the moment</div>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	data: () => ({

	}),
	methods: {

	},
	computed: {
		friendRequests () {
			return this.$store.state.sentFriendRequests;
		},
		userID () {
			return this.$store.state.user.id;
		}
	},
	async mounted () {
		if (this.userID) {
			await this.$store.dispatch('GET_MY_SENT_FRIEND_REQUESTS');
		}
	}
}
</script>