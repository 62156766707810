<template>
	<v-card>
    <v-card-title>
      No Friends Found Yet
    </v-card-title>
    <v-card-text>
      <p class="card-text">Your Streamzy friends are people that you can share your movie choices with, and see what they're interested in watching too!</p>
			<p class="card-text">You can get started by creating a free account, and then adding some friends.</p>
      
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <create-account-button />
    </v-card-actions>
  </v-card>
</template>

<script>
import CreateAccountButton from '../CreateAccountButton.vue';

export default {
  components: {
    CreateAccountButton,

  },
	data: () => ({

	}),
	methods: {

	},
	computed: {

	},
	watch: {

	},
	mounted () {

	}
}
</script>

<style lang="scss" scoped>

</style>