<template>
	<v-card>
		<v-card-title class="justify-center">
			{{user.firstName}} {{user.lastName}}
		</v-card-title>
		<v-card-text class="text-center">
			<v-avatar color="primary" class="mb-2">{{userInitials()}}</v-avatar>
			<p>Joined Streamzy on {{new Date(user.created_at).toDateString()}}</p>
			<v-row>
				<v-col cols="12" md="6">
					<v-text-field label="Email" disabled :value="user.email"></v-text-field>
					<p class="text-left">
						<v-switch v-model="showHomeSplash" label="Show Introduction on Home Screen"></v-switch>
					</p>
					<v-form v-model="passwordValid" v-if="updatePassword">
						<v-text-field
							v-model="currentPassword"
							:disabled="disabled"
							:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
							:rules="[rules.required]"
							:type="showCurrent ? 'text' : 'password'"
							name="input-1"
							label="Current Password"
							@click:append="showPassword = !showPassword"
						></v-text-field>

						<v-text-field
							v-model="newPassword"
							:disabled="disabled"
							:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
							:rules="[rules.required, rules.min, passwordMatch]"
							:type="showPassword ? 'text' : 'password'"
							name="input-1"
							label="New Password"
							hint="At least 8 characters"
							counter
							@click:append="showPassword = !showPassword"
						></v-text-field>
						
						<v-text-field
							v-model="confirmPassword"
							:disabled="disabled"
							:append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
							:rules="[rules.required, rules.min, passwordMatch]"
							:type="showConfirm ? 'text' : 'password'"
							name="input-2"
							label="Confirm New Password"
							hint="At least 8 characters"
							counter
							class="input-group--focused"
							@click:append="showConfirm = !showConfirm"
						></v-text-field>
					</v-form>
			<p v-if="newPasswordSuccess">
				<v-icon color="success">mdi-check-circle-outline</v-icon>
				Your new password has been saved.</p>
			<p v-if="newPasswordError">
				<v-icon color="error">mdi-alert-circle</v-icon>
				An error occurred and your password was not updated. Please try again.
			</p>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="secondary" v-if="!updatePassword"
				@click="updatePassword = !updatePassword">Change Password</v-btn>
			<v-btn color="primary" v-if="updatePassword" :disabled="!passwordValid"
				@click="saveNewPassword">Save New Password</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import library from '../../library/library';

export default {
	data: () => ({
		updatePassword: false,
		currentPassword: '',
		newPassword: '',
    confirmPassword: '',
		showCurrent: false,
    showPassword: false,
    showConfirm: false,
    disabled: false,
		passwordValid: false,
		showHomeSplash: true,
		rules: {
      required: value => !!value || 'Required',
      min: v => v.length >= 8 || 'Min 8 characters',
    },
	}),
	methods: {
		saveNewPassword () {
			console.log('save new');
			this.$store.commit('SET_UPDATING_PASSWORD', {
				currentPassword: this.currentPassword,
				newPassword: this.newPassword
			});
			this.$store.dispatch('UPDATE_USER_PASSWORD');
		},
		setHomeSplash () {
      const val = localStorage.getItem('hideSplash') == 'true';
      if (val && val == true) {
				// console.log('val is true, hiding');
        this.showHomeSplash = false;
      }
    }
	},
	computed: {
		user () {
			return this.$store.state.user;
		},
		passwordMatch () {
      return this.newPassword === this.confirmPassword || 'Passwords do not match';
    },
		newPasswordSuccess () {
      return this.$store.state.newPasswordSetSuccess;
    },
    newPasswordError () {
      return this.$store.state.newPasswordSetError;
    },
	},
	watch: {
		showHomeSplash (newVal) {
			if (newVal) {
				console.log('setting no hide');
				localStorage.setItem('hideSplash', false);
			} else {
				console.log('setting to hide');
				localStorage.setItem('hideSplash', true);
			}
		}
	},
	mounted () {
		this.setHomeSplash();
	},
	created () {
		this.userInitials = library.getUserInitials;
	},
	beforeDestroy () {
		this.$store.commit('CLEAR_PASSWORD_DATA');
	}
}
</script>

<style lang="scss" scoped>

</style>