<template>
	<v-sheet>
		<v-card-title>{{cardTitle}}</v-card-title>
		<v-row>
			<v-slide-group>
				<v-slide-item v-for="movie in movies" :key="movie.id">
					<movie-card :movie="movie" cardSize="300"></movie-card>
				</v-slide-item>
			</v-slide-group>
		</v-row>
	</v-sheet>
</template>

<script>
import MovieCard from './movies/MovieCard.vue';

export default {
	components: {
		MovieCard,
	},
	props: ['movies', 'userID', 'cardTitle', 'title', 'media'],
	data: () => ({
		
	}),
	methods: {

	},
	computed: {
		
	},
	watch: {

	},
	mounted () {

	}
}
</script>

