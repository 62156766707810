<template>
  <v-navigation-drawer app v-model="drawer">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6">
          <v-img :src="require('../../assets/logo-300.png')"></v-img>
        </v-list-item-title>
        <v-list-item-subtitle class="text-center">
          {{ userEmail }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>
    <v-list nav>
      <v-list-item
        v-for="item in navItems"
        :key="item.title"
        link
        :to="item.link"
      >
        <v-list-item-icon>
          <v-icon color="primary">{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider v-if="!userID"></v-divider>
    <template v-slot:append>
      <div class="pa-2">
        <v-btn v-if="!userID" block color="primary" @click="logUserIn">
          Log In
        </v-btn>
        <v-btn v-if="userID" block color="primary" @click="logUserOut">
          Logout
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  data: () => ({
    navItems: [
      { title: "Home", icon: "mdi-home", link: "/" },
      {
        title: "Watch List",
        icon: "mdi-format-list-bulleted",
        link: "/watch-list",
      },
      { title: "Friends", icon: "mdi-account-heart-outline", link: "/friends" },
      { title: "Shares", icon: "mdi-account-multiple", link: "/shares" },
      { title: "Matches", icon: "mdi-movie-open-outline", link: "/matches" },
      {
        title: "Profile",
        icon: "mdi-account-circle-outline",
        link: "/profile",
      },
    ],
  }),
  methods: {
    logUserOut() {
      localStorage.setItem("email", "");
      localStorage.setItem("password", "");
      this.$store.commit("LOG_USER_OUT");
      this.$router.push("/");
    },
    logUserIn() {
      this.$store.commit("TOGGLE_ACCOUNT_MODAL", true);
    },
  },
  computed: {
    drawer: {
      get: function () {
        return this.$store.state.navDrawer;
      },
      set: function (val) {
        this.$store.commit("TOGGLE_NAV_DRAWER", val);
      },
    },
    userEmail() {
      return this.$store.state.user.id
        ? this.$store.state.user.email
        : "Anonymous User";
    },
    userID() {
      return this.$store.state.user.id;
    },
  },
  watch: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
</style>