<template>
  <v-app-bar app>
    <v-app-bar-nav-icon
      v-if="routeIsHome"
      @click="toggleNavDrawer"
    ></v-app-bar-nav-icon>
    <v-btn v-if="!routeIsHome" icon @click="goBack">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <div class="d-flex align-center">
      <span class="app-title">{{ pageTitle }}</span>
      <!-- <v-img max-height="20" contain :src="require('./assets/logo-cropped.png')"></v-img> -->
    </div>
    <v-spacer></v-spacer>
    <search-row v-if="!isOnMobileDevice"></search-row>

    <v-spacer></v-spacer>
    <v-btn icon v-show="isOnMobileDevice">
      <v-icon color="primary" @click="toggleUserSearch">mdi-magnify</v-icon>
    </v-btn>
    <v-menu offset-y light>
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on">
          <v-icon color="primary"
            >{{ newNotificationCount ? "mdi-bell-badge" : "mdi-bell-outline" }}
          </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-list two-line flat>
          <v-list-item
            v-for="(item, index) in notifications"
            :key="index"
            :class="item.status === 'new' ? 'new-notification' : null"
            :to="findItemLink(item.type)"
            :id="item.id"
            @click="markNotificationRead($event)"
          >
            <v-list-item-icon>
              <v-icon :color="item.status === 'new' ? 'primary' : null"
                >mdi-account-plus-outline</v-icon
              >
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.message }}</v-list-item-title>
              <v-list-item-subtitle>{{
                findElapsedTime(item.created_at)
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-btn depressed color="primary" @click="clearAllNotifications"
            >Clear All</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-menu>
    <!-- <v-btn text>
            <v-icon color="primary">mdi-cog-outline</v-icon>
        </v-btn> -->
    <v-btn text to="/profile" icon class="mr-4 ml-4" color="primary">
      <v-avatar v-if="userID" color="secondary">{{
        getUserInitials()
      }}</v-avatar>
      <v-icon v-if="!userID" color="primary">mdi-account-circle-outline</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import SearchRow from '../search/SearchRow.vue';
import library from "../../library/library";

export default {
  components: {
    SearchRow,
  },
  data: () => ({}),
  methods: {
    toggleNavDrawer() {
      this.$store.commit("TOGGLE_NAV_DRAWER", !this.navDrawer);
    },
    toggleAccountModal() {
      this.$store.commit("TOGGLE_ACCOUNT_MODAL", !this.accountModal);
    },
    toggleUserSearch () {
      this.$store.commit('TOGGLE_SEARCH_VIEW');
    },
    clearAllNotifications() {
      // console.log("clear all");
      this.$store.dispatch("MARK_ALL_NOTIFICATIONS_READ");
    },
    findItemLink(item) {
      switch (item) {
        case "friend":
          return "/friends";
        case "share":
          return "/shares";
        case "match":
          return "/matches";
        default:
          return "/";
      }
    },
    markNotificationRead(event) {
      let id = null;
      let x = 0;
      while (!id) {
        id = event.path[x].id;
        x++;
      }
      this.$store.dispatch("MARK_NOTIFICATION_READ", id);
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  computed: {
    userID() {
      return this.$store.state.user.id;
    },
    notifications() {
      return this.$store.state.notifications.slice(0, 5);
    },
    navDrawer() {
      return this.$store.state.navDrawer;
    },
    newNotificationCount() {
      return this.$store.getters.newNotificationCount;
    },
    accountModal() {
      return this.$store.state.showAccountModal;
    },
    routeIsHome() {
      return this.$route.path === "/";
    },
    pageTitle() {
      return this.routeIsHome ? process.env.VUE_APP_TITLE : this.$route.name;
    },
    isOnMobileDevice() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  watch: {

  },
  mounted() {

  },
  created() {
    this.findElapsedTime = library.findElapsedTime;
    this.getUserInitials = library.getUserInitials;
  },
};
</script>

<style lang="scss" scoped>
.new-notification {
  font-weight: bold;
}
</style>