const getters = {
	userLoggedIn: state => { // may be used in router for user auth?
		return !!state.user.id;
	},
	findShare: state => id => {
		return state.entities.shares[id];
	},
	sharedMovies: (state, getters) => {
		return state.results.shares.map(id => getters.findShare(id));
	},
	findComment: state => id => {
		return state.entities.comments[id];
	},
	shareComments: (state, getters) => id => { // first ID is share ID, ID in callback is Comment ID
		return state.entities.shares[id].comments.map(id => getters.findComment(id));
	},
	findMovieDetails: state => id => {
		return state.movieDetailData.find(movie => movie.id == id);
	},
	newNotificationCount: state => {
		return state.notifications.filter(item => item.status === 'new').length;
	},
	/* eslint-disable-next-line */
	getAllNewNotificationIDs: state => {
		// return state.notifications.filter(item => item.status === 'new')
		return state.notifications.filter(item => item.status === 'new').map(item => item.id).join(',');
	}
};

export default getters;