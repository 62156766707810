<template>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-card>
          <v-card-title>Reset Password</v-card-title>
          <v-card-text>
            <p v-if="invalidTokenError">
              <v-icon color="error">mdi-alert-circle</v-icon>
              Your token is invalid or expired. Please reset your password again.
            </p>
            <p v-if="validTokenSuccess">
              <v-icon color="success">mdi-check-circle-outline</v-icon>
              Your token has been verified.
              </p>
            Type your new password here:
            <v-form v-model="passwordValid">
              <v-text-field
                v-model="password"
                :disabled="disabled || invalidTokenError"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min, passwordMatch]"
                :type="showPassword ? 'text' : 'password'"
                name="input-1"
                label="Password"
                hint="At least 8 characters"
                counter
                @click:append="showPassword = !showPassword"
              ></v-text-field>
              
              <v-text-field
                v-model="confirmPassword"
                :disabled="disabled || invalidTokenError"
                :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min, passwordMatch]"
                :type="showConfirm ? 'text' : 'password'"
                name="input-2"
                label="Confirm Password"
                hint="At least 8 characters"
                counter
                class="input-group--focused"
                @click:append="showConfirm = !showConfirm"
              ></v-text-field>
            </v-form>
            <p v-if="newPasswordSuccess">
              <v-icon color="success">mdi-check-circle-outline</v-icon>
              Your new password has been saved. You may now login.</p>
            <p v-if="newPasswordError">
              <v-icon color="error">mdi-alert-circle</v-icon>
              Your password was not able to be saved. Please reset your password again.
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" depressed @click="clearForm" 
              :disabled="clearDisabled">Clear Form</v-btn>
            <v-btn color="primary" depressed :disabled="!passwordValid" 
              @click="setNewPassword">Save Password</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      
    </v-row>
</template>

<script>
export default {
  data: () => ({
    token: '',
    passwordValid: false,
    password: '',
    confirmPassword: '',
    showPassword: false,
    showConfirm: false,
    disabled: false,
    clearDisabled: false,
    rules: {
      required: value => !!value || 'Required',
      min: v => v.length >= 8 || 'Min 8 characters',
    },
  }),
  methods: {
    verifyToken () {
      if (!this.token) {
        return false;
      }
      this.$store.commit('SET_PASSWORD_TOKEN', this.token);
      this.$store.dispatch('VERIFY_PASSWORD_TOKEN');
    },
    clearForm () {
      this.passwordValid = false;
      this.password = '';
      this.confirmPassword = '';
    },
    setNewPassword () {
      this.showPassword = false;
      this.showConfirm = false;
      this.disabled = true;
      this.$store.commit('SET_NEW_USER_PASSWORD', this.password);
      this.$store.dispatch('SAVE_NEW_USER_PASSWORD');
    }
  },
  computed: {
    passwordMatch () {
      return this.password === this.confirmPassword || 'Passwords do not match';
    },
    invalidTokenError () {
      return this.$store.state.invalidTokenError;
    },
    validTokenSuccess () {
      return this.$store.state.validTokenSuccess;
    },
    newPasswordSuccess () {
      return this.$store.state.newPasswordSetSuccess;
    },
    newPasswordError () {
      return this.$store.state.newPasswordSetError;
    }
  },
  watch: {
    newPasswordSuccess (val) {
      if (val) {
        setTimeout(() => {
          this.$store.commit('TOGGLE_ACCOUNT_MODAL', true);
        }, 1000);
      }
    },
    newPasswordError (val) {
      if (val) {
        this.clearDisabled = true;
        this.passwordValid = false;
      }
    }
  },
  mounted () {
    this.token = this.$route.query.token;
    this.verifyToken();
  }
}
</script>

<style lang="scss" scoped>

</style>