import Vue from 'vue';

const mutations = {
	/* NAV ITEMS */
	TOGGLE_NAV_DRAWER (state, value) {
		state.navDrawer = value;
	},
	/* USER ACCOUNT MODAL */
	TOGGLE_ACCOUNT_MODAL (state, value) {
		state.showAccountModal = value;
	},

	/* USER SEARCH ON MOBILE */
	TOGGLE_SEARCH_VIEW (state) {
		state.showUserSearch = !state.showUserSearch;
	},

	/* USER ACCOUNT INFO */
	SET_USER_EMAIL (state, email) {
		state.user.email = email;
	},
	SET_USER_PASSWORD (state, password) { // used during new account creation
		state.user.password = password;
	},
	SET_USER_FIRST_NAME (state, name) {
		state.user.firstName = name;
	},
	SET_USER_LAST_NAME (state, name) {
		state.user.lastName = name;
	},
	SET_USER_ID (state, id) {
		state.user.id = id;
	},
	SET_LOGGED_IN_USER_DATA (state, user) {
		state.user.id = user.id;
		state.user.firstName = user.first_name;
		state.user.lastName = user.last_name;
		state.user.created_at = user.created_at;
	},
	LOG_USER_OUT (state) {
		state.user.id = '';
		state.user.email = '';
		state.user.firstName = '';
		state.user.lastName = '';
		state.user.password = '';
		state.userMovieFavorites = [];
		state.populatedMovieFavorites = [];
		state.friendsList = [];
		state.pendingFriendRequests = [];
		state.notifications = [];
		state.entities.shares = {};
		state.entities.comments = {};
		state.results.shares = [];
	},

	/**
	 * RESETTING USER PASSWORD
	 */
	RESET_PASSWORD_EMAIL_SUCCESS (state, value) { // used when the user resets their password
		state.forgotPasswordEmailSuccess = value;
	},
	SET_PASSWORD_TOKEN (state, token) {
		state.forgotPasswordToken = token;
	},
	SET_INVALID_TOKEN_ERROR (state, value) {
		state.invalidTokenError = value;
	},
	SET_VALID_TOKEN_SUCCESS (state, value) {
		state.validTokenSuccess = value;
	},
	SET_NEW_USER_PASSWORD (state, value) { // used to reset existing user's password
		state.newUserPassword = value;
	},
	SET_NEW_PASSWORD_SUCCESS (state, value) {
		state.newPasswordSetSuccess = value;
	},
	SET_NEW_PASSWORD_ERROR (state, value) {
		state.newPasswordSetError = value;
	},

	/**
	 * UPDATING USER PASSWORD
	 */
	SET_UPDATING_PASSWORD (state, value) {
		state.updatePassword.currentPassword = value.currentPassword;
		state.updatePassword.newPassword = value.newPassword;
	},
	CLEAR_PASSWORD_DATA (state) {
		state.newPasswordSetSuccess = false;
		state.newPasswordSetError = false;
		state.updatePassword.currentPassword = '';
		state.updatePassword.newPassword = '';
		state.forgotPasswordEmailSuccess = false;
		state.invalidTokenError = false;
		state.validTokenSuccess = false;
		state.newUserPassword = '';
	},

	/* MOVIE DATA */
	SET_TRENDING_MOVIES (state, movies) { // from TMDB
		state.trendingMovies = movies;
	},
	SET_TRENDING_SHOWS (state, shows) { // from TMDB
		state.trendingShows = shows;
	},
	SET_MOVIE_DETAIL_VIEW (state, movie) { // from TMDB
		state.movieDetailView = {...movie}
	},
	SET_MOVIE_DETAIL_DATA (state, data) { // from TMDB
		state.movieDetailData.push(data);
	},
	SET_MOVIE_CREDITS_DATA (state, data) { // from TMDB
		state.movieCreditsData.push(data);
	},
	SET_MOVIE_GENRE_LIST (state, list) { // from TMDB
		state.movieGenreList = list;
	},
	SET_SEARCH_RESULTS (state, results) { // from TMDB
		state.searchResults = results;
	},
	/* eslint-disable-next-line */
	SET_MOVIE_WATCH_PROVIDER (state, payload) { // from TMDB
		if (payload.movie_id) {
			const movie = state.trendingMovies.find(movie => movie.id === payload.movie_id);
			movie.provider = payload;
		}
		
	},
	
	/* USER MOVIE LIST */
	SET_USER_MOVIE_LIST (state, movies) { // from My API
		state.userMovieFavorites = movies;
	},
	CLEAR_USER_MOVIE_LIST (state) {
		state.userMovieFavorites = [];
	},
	SET_POPULATED_MOVIE_LIST_DATA (state, movie) {
		state.populatedMovieFavorites.push(movie);
	},
	CLEAR_POPULATED_MOVIE_LIST (state) {
		state.populatedMovieFavorites = [];
	},

	/* FRIENDS LIST & REQUESTS */
	SET_FRIENDS_LIST (state, friends) {
		state.friendsList = friends;
	},
	SET_PENDING_REQUESTS (state, requests) {
		state.pendingFriendRequests = requests;
	},
	SET_SENT_REQUESTS (state, requests) {
		state.sentFriendRequests = requests;
	},
	SHOW_SHARE_MODAL (state) {
		state.showShareModal = true;
	},
	CLOSE_SHARE_MODAL (state) {
		Object.keys(state.currentShare).forEach(item => state.currentShare[item] = '');
		state.showShareModal = false;
	},
	SHOW_RESPOND_SHARE_MODAL (state) {
		state.showRespondShareModal = true;
	},
	CLOSE_RESPOND_SHARE_MODAL (state) {
		Object.keys(state.currentShare).forEach(item => state.currentShare[item] = '');
		state.showRespondShareModal = false;
	},
	SET_CURRENT_SHARE_MOVIE (state, movie) {
		state.currentShare.movie_id = movie.id;
		state.currentShare.media_type = movie.media;
	},
	SET_SHARE_SENT (state, value) {
		state.shareSent = value;
	},

	/* RESPONSE TO SHARE */
	SET_CURRENT_RESPONSE_MOVIE (state, movie) {
		state.currentResponse.movie_id = movie.id;
		state.currentResponse.media_type = movie.media;
	},
	SET_CURRENT_RESPONSE_ID (state, id) {
		state.current_response_share_id = id;
	},
	SET_CURRENT_SHARE_FRIEND (state, friend) {
		state.currentShare.share_to_email = friend;
	},
	SET_CURRENT_RESPONSE_FRIEND (state, friend) {
		state.currentResponse.respond_to_email = friend;
	},
	SET_CURRENT_SHARE_DETAILS (state, details) {
		state.currentShare.share_from_comments = details.comments;
		state.currentShare.share_from_excite = details.exciteLevel;
	},
	SET_CURRENT_RESPONSE_DETAILS (state, details) {
		state.currentResponse.response_comments = details.comments;
		state.currentResponse.response_excite = details.exciteLevel;
	},
	SET_INCOMING_SHARES (state, shares) {
		state.incomingShares = shares;
	},
	SET_OUTGOING_SHARES (state, shares) {
		state.outgoingShares = shares;
	},
	SET_POPULATED_INCOMING_SHARE_DATA (state, movie) {
		//state.populatedIncomingShares.push(movie);
		// const shared = state.incomingShares.find(item => item.str_id === movie.str_id);
		// shared.movieData = movie;
		state.movieDetailData.push(movie);
	},

	SET_INCOMING_MATCHES (state, matches) {
		state.incomingMatches = matches;
	},
	CLEAR_POPULATED_MOVIE_MATCHES (state) {
		state.populatedMatches = [];
	},
	SET_POPULATED_MATCH_DATA (state, matchData) {
		state.populatedMatches.push(matchData);
	},
	UPDATE_ENTITIES (state, {entities}) {
		// https://blog.pepf.nl/2017/06/using-normalizr-to-work-with-nested-data-in-vue-vuex/
		for (let type in entities) {
			for (let entity in entities[type]) {
				const oldObj = state.entities[type][entity] || {};
				const newObj = Object.assign(oldObj, entities[type][entity]); // Merge the new data in the old object
				Vue.set(state.entities[type], entity, newObj);// Make sure new entities are also reactive
			}
		}
	},
	UPDATE_RESULTS (state, {results}) {
		for (let type in results) {
			//https://stackoverflow.com/questions/45730610/vue-js-best-way-to-update-entire-array
			state.results[type] = [...results[type]];
		}
	},

	SET_USER_NOTIFICATIONS (state, notis) {
		state.notifications = notis;
	}

};

export default mutations;